import React from 'react'

const styling = {
    color: "#FF4058",
    fontSize: "15px",
    marginTop: "-10px",
}

const list1 = [
    {
        id: 0,
        link: "https://my.itinker.in/wp-login.php?redirect_to=https%3A%2F%2Fmy.itinker.in%2F&bp-auth=1",
        text: "Login",
    },
    {
        id: 1,
        link: "/features",
        text: "Features",
    },
    {
        id: 2,
        link: "/schools",
        text: "For Schools",
    },

]

const list2 = [
    {
        id: 0,
        link: "/tinkerWorld",
        text: "TinkerWorld",
    },
    {
        id: 1,
        link: "/technovation",
        text: "Technovation",
    },
    {
        id: 2,
        link: "/about",
        text: "About",
    },
    {
        id: 3,
        link: "/privacy",
        text: "Privacy Policy",
    },
]

// render the item
// id --> id of the item/object to render, link --> the link to render on click by the user
// text --> the text to show the user
const RenderItem = (item) => {
    return (
        <li key={item.id}>
            <a href={item.link} className="text-decoration-none text-white">
                {item.text}
            </a>
        </li>
    )
}
function Footer() {
    return (
        <footer className="py-5" xmlns="http://www.w3.org/1999/html">
            <div className="container-fluid">
                <div className="row">

                    {/* logo of the company */}
                    <div className="col-md-2 text-center">
                        <h1 className="text-white">
                            <img src="images/footer-logo.png" alt="iTinker" className="img-fluid" />
                        </h1>
                    </div>

                    <div className="col-md-2">
                        <ul className="list-unstyled ">

                            {/* render first column of the footer */}
                            {list1.map(item => (
                                <RenderItem key={item.id}
                                    id={item.id}
                                    link={item.link}
                                    text={item.text} />
                            ))}

                        </ul>
                    </div>

                    <div className="col-md-2 text-left">
                        <ul className="list-unstyled ">

                            {/* render 2 column of the footer */}
                            {list2.map(item => (
                                <RenderItem key={item.id}
                                    id={item.id}
                                    link={item.link}
                                    text={item.text} />
                            ))}

                        </ul>
                    </div>

                    <div className="col-md-2">
                        <ul className="list-unstyled ">
                            <li><a href="/fullTimeExpert" className="text-decoration-none text-white">Careers</a></li>
                            <li><a href="/contactUs" className="text-decoration-none text-white">Contact Us</a></li>
                            <li><a href="/terms" className="text-decoration-none text-white">Terms of Service</a></li>

                        </ul>
                    </div>

                    <div className="col-md-2">
                        <h6 className="text-white">Come, meet us -</h6>
                        <address className="text-white pt-3">
                            Chandigarh, India
                        </address>
                        <ul className="list-unstyled ">
                            <li>
                                <address className="text-white pt-3">
                                    Write to us - <a href="mailto: hello@3dwe.in">Hello iTinker</a>
                                </address>
                            </li>
                        </ul>
                    </div>

                    {/* link to companies various handles */}
                    <div className="col-md-2 text-center">
                        <ul className="list-unstyled list-inline">
                            <li className="list-inline-item"><a href="https://www.instagram.com/itinker_in/" target="_blank"
                                rel="noreferrer" className="text-decoration-none text-white"> <i className="fa fa-instagram"
                                    aria-hidden="true"/></a>
                            </li>
                            <li className="list-inline-item"><a href="https://www.linkedin.com/company/itinker" target="_blank"
                                rel="noreferrer" className="text-decoration-none text-white"> <i className="fa fa-linkedin"
                                    aria-hidden="true"/></a>
                            </li>
                            <li className="list-inline-item"><a href="https://www.facebook.com/itinker.in" target="_blank"
                                rel="noreferrer" className="text-decoration-none text-white"> <i className="fa fa-facebook"
                                    aria-hidden="true"/></a>
                            </li>
                            <li className="list-inline-item"><a href="https://www.youtube.com/channel/UCeSYbzo-rPZ4Quavwwc3bsA" target="_blank"
                                rel="noreferrer" className="text-decoration-none text-white"> <i className="fa fa-youtube-square"
                                    aria-hidden="true"/></a>
                            </li>
                        </ul>

                        {/* copy write line */}
                        <p><a href="/" style={styling} >&copy; 2021 iTinker</a></p>
                        
                    </div>

                </div>
            </div>
        </footer>

    )
}

export default Footer
