import React from 'react'

const RenderImage = () => {
    return (
        <div className="col-md-6">
            <img src="images/mission-img.png"
                 alt="mission"
                 className="img-fluid"
                 style={{marginTop: "-70px"}}/>
        </div>
    )
}

const RenderWrittenContent = () => {
    return (
        <p className="text-white">
            To build a
            <b> social learning ecosystem </b>
            that is secure, open, transparent and easily
            adaptable to its environment.
        </p>
    )
}

function Mission(props) {
    if (props.isMobile)
        return (
            <>
                <div className="row pt-5">
                    <div className="col-md-6">
                        <div className="py-5">

                            <h1 className="text-white">Our Mission</h1>
                            <RenderImage/>
                            <RenderWrittenContent/>

                        </div>
                    </div>
                </div>
            </>
        )
    else
        return (
            <>
                <div className="row pt-5">

                    <RenderImage/>

                    <div className="col-md-6">
                        <div className="py-5">

                            <h1 className="text-white">Our Mission</h1>

                            <p><br/></p>

                            <RenderWrittenContent/>

                        </div>
                    </div>

                </div>
            </>
        )
}

export default Mission
