import React from 'react'
import CountUp from 'react-countup';

// handles the rendering of slide image in the case the selected career type freelancing
const FreelancingSlideImage = () => {
    return (
        <div className="page-banner py-5 text-center">
            <div className="container wow zoomIn">
                <div className="row">

                    <div className="col-md-8 md-offset-1">

                        <h2>Help Us Help India Unleash It's <span>Human Potential</span></h2><br/>

                        <div className="d-md-inline-flex w-100 pt-5">

                            {/* it renders the value on the screen by taking the duration as per mentioned */}
                            <div className="counter">
                                <h2 className="timer count-title count-number">
                                    <CountUp
                                        className="account-balance"
                                        start={0}
                                        end={15}
                                        duration={2.3}
                                        useEasing={true}
                                        useGrouping={true}
                                        separator=" "
                                    />
                                </h2>
                                <p className="count-text"><b>Experts</b></p>
                            </div>

                            <div className="counter">
                                <h2 className="timer count-title count-number">
                                    <CountUp
                                        className="account-balance"
                                        start={0}
                                        end={10}
                                        duration={2.3}
                                        useEasing={true}
                                        useGrouping={true}
                                        separator=" "
                                    />
                                </h2>
                                <p className="count-text"><b>Schools</b></p>
                            </div>

                            <div className="counter">
                                <h2 className="timer count-title count-number">
                                    <CountUp
                                        className="account-balance"
                                        start={0}
                                        end={4}
                                        duration={2.3}
                                        useEasing={true}
                                        useGrouping={true}
                                        separator=" "
                                    />
                                </h2>
                                <p className="count-text"><b>States/UTS</b></p>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-4">
                        <img src="images/freelance1.png" alt='freelancing' className="img-fluid"/>
                    </div>

                </div>
            </div>
        </div>
    )
}

// handles the rendering of slide image in the case the selected career type full time expert
const FullTimeExpertSlideImage = () => {
    return (
        <div className="page-banner py-5 text-center">
            <div className="container wow zoomIn">
                <div className="row">

                    <div className="col-md-9">
                        <h2>Let's Help India Unleash It's <span><br/>Human Potential</span></h2>
                        <br/>
                    </div>

                    <div className="col-md-6">
                        <img src="images/career-b.png" alt='career' className="img-fluid"/>
                    </div>

                </div>
            </div>
        </div>
    )
}

//main function which handles the render from the file on the screen
function SlideImage(props) {

    // true if the selected career type is full time expert
    if (props.CareerType === "fullTimeExpert") {
        return (
            <FullTimeExpertSlideImage/>
        )
    } else
        return (
            <FreelancingSlideImage/>
        )
}

export default SlideImage
