import React from 'react'

const HandleRenderingOnScreen = (props) =>{
    if(props.isMobile){
        return(
            <div className="row">

                <div className="col-md-6">
                    <div className="py-5">
                        <h1>Our Vision</h1>
                        <RenderImage/>
                        <RenderWrittenContent/>
                    </div>
                </div>


            </div>
        )
    }
    else
    return(
        <div className="row">

            <div className="col-md-6">
                <div className="py-5">
                    <h1>Our Vision</h1>
                    <p><br /></p>
                    <RenderWrittenContent/>
                </div>
            </div>

            <RenderImage/>
        </div>
    )
}

const RenderWrittenContent = () =>{
    return(
        <p>
            <b>To make school children future ready </b>
            by teaching them the life skills necessary for
            future success, by exposing them to upcoming technological advancements and by onboarding
            them on a journey from an idea to a sellable product.
        </p>
    )
}

const RenderImage = () =>{
    return(
        <div className="col-md-6">
            <img src="images/vision-img.png"
                 alt="Vision"
                 className="img-fluid"
                 style={{ marginTop: "-70px" }} />
        </div>
    )
}

// vision of the company
function Vision(props) {
    return (
        <>
            <HandleRenderingOnScreen isMobile={props.isMobile}/>
        </>
    )
}

export default Vision
