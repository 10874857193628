import React from 'react'
import Vimeo from '@u-wave/react-vimeo';
import {Carousel} from "react-responsive-carousel";
import {Grid, makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    carousel: {
        margin: theme.spacing(2)
    }
}));

// handles the styling of title
const titleStyle = {
    marginTop: "25px",
    marginBottom: "25px",
}

// handles rendering of title on the screen
const RenderTitle = ({title}) => {
    return (
        <div className="row text-center wow zoomIn" style={titleStyle}>
            <a href="#firstVideo">
                <div className="col-md-12">
                    <h1>More From {title}</h1>
                </div>
            </a>
        </div>
    )
}

function MoreFromStudent(props) {
    // className to pass to carousel
    const classes = useStyles();

    return (
        <div>
            {/* render title oon the screen */}
            <RenderTitle key={props.id} title={props.title}/>

            {/* handles rendering of videos on the screen */}
            <Grid item md={8} xs={12} style={{margin : "auto"}} >

                {/* it returns the carousel of the you tube videos with thumbnail */}
                <Carousel
                    autoPlay={false}
                    className={classes.carousel}
                    emulateTouch={true}
                    showArrows={true}
                    showThumbs={false}
                    showStatus={false}
                    infiniteLoop={true}
                >
                    {/* runs for each item in the data send as the props and results
                    in carousel of the videos */}
                    {props.videos.map(video => (
                        <Vimeo
                            key={video.id}
                            video={video.videoUrl}
                        />
                    ))}

                </Carousel>
            </Grid>




            {/*<div className="row tm-catalog-item-list">*/}

            {/*    {props.videos.map(video => (*/}

            {/*        <Vimeo*/}
            {/*            video={video.videoUrl}*/}
            {/*            autoplay*/}
            {/*        />*/}
            {/*        <div key={video.id} className="col-lg-3 col-md-3 tm-catalog-item">*/}
            {/*            <div id="firstVideo" className="position-relative tm-thumbnail-container">*/}

            {/*                <iframe*/}
            {/*                    src={video.videoUrl}*/}
            {/*                    title={props.title}*/}
            {/*                    width="300"*/}
            {/*                    height="200"*/}
            {/*                    frameBorder="0"*/}
            {/*                    allow="autoplay; fullscreen"*/}
            {/*                    allowFullScreen={true}>*/}

            {/*                </iframe>*/}

            {/*            </div>*/}
            {/*        </div>*/}

            {/*    ))}*/}

            {/*</div>*/}

        </div>
    )
}

export default MoreFromStudent
