import React from 'react'
import {CompanyValues} from '../../utils/constants'

const RenderImage = () => {
    return (
        <div className="col-md-6">
            <img src="images/values-img.png"
                 alt="schools"
                 className="img-fluid"
                 style={{marginTop: "-70px"}}/>
        </div>
    )
}

const RenderWrittenContent = () => {
    return (
        <p>
            <b>The Values that provide our solid foundation -</b>

            {/* renders each value */}
            {CompanyValues.map(value => (
                <ol key={value.id}>
                    <b>{value.heading}</b>
                    {value.text}
                </ol>
            ))}
        </p>
    )
}

function Values(props) {
    if (props.isMobile)
        return (
            <>
                <div className="row py-5">
                    <div className="col-md-6">
                        <div className="py-5">
                            {/* title of the component */}
                            <h1>Our Values</h1>
                            <RenderImage/>
                            <RenderWrittenContent/>
                        </div>
                    </div>

                </div>
            </>
        )
    else
        return (
            <>
                <div className="row py-5">
                    <div className="col-md-6">
                        <div className="py-5">
                            {/* title of the component */}
                            <h1>Our Values</h1>
                            <p><br/></p>
                            <RenderWrittenContent/>
                        </div>
                    </div>

                    <RenderImage/>

                </div>
            </>
        )
}

export default Values
