import React, {useEffect} from 'react'
import {slides, tinkerWorldVideos} from '../../utils/constants'

// components required to load the tinker world page of the website 
import SlideShowLook from '../slides';
import HallOfFame from './hall_of_fame';
import CarouselVideo from "./video_crousel";

function TinkerWorld(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {/* slide show of students */}
            <SlideShowLook slides={slides.slice(1)}/>

            <HallOfFame isMobile={props.isMobile}/>

            {/* the videos of the students in carousel */}
            <CarouselVideo data={tinkerWorldVideos}/>
        </>
    )
}

export default TinkerWorld
