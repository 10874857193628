import React from 'react'
import {ForInfo} from '../../utils/constants'

const RenderImage = () => {
    return (
        <div className="col-md-6 wow slideInLeft">
            <img src="images/schools.png" alt="schools" className="img-fluid"/>
        </div>
    )
}

const RenderTitle = () => {
    return (
        <h1 className="text-white" >iTinker For Schools</h1>
    )
}

const RenderInfo = () => {
    return (
        <>
            {/* array to render */}
            {ForInfo.map(each => (
                <p key={each.id} className="pt-3 text-white"><b>{each.title}</b>{each.text}</p>
            ))}

            <a href="../schools/schools.js"
               className="d-inline-block mt-3 view-more rounded text-white text-decoration-none">
                View More
            </a>
        </>
    )
}

const HandleRenderingOnScreen = (props) => {
    if (props.isMobile) {
        return (
            <div className="row">


                <div className="col-md-6 wow slideInRight">
                    <div className="py-5">

                        <RenderTitle/>
                        <RenderImage/>
                        <RenderInfo/>

                    </div>
                </div>

            </div>
        )
    } else
        return (
            <div className="row">

                <RenderImage/>

                <div className="col-md-6 wow slideInRight">
                    <div className="py-5">

                        <RenderTitle/>

                        <RenderInfo/>

                    </div>
                </div>

            </div>
        )
}

function ForSchool(props) {

    return (
        <>
            <HandleRenderingOnScreen isMobile={props.isMobile}/>
        </>
    )
}

export default ForSchool
