import React from 'react'

//files required to load for the carousel look
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import the constant arrays having values of open positions available as per selected career type
import {
    openPositionValuesFreelancing,
    openPositionValuesFullTimeExpert
} from "../../utils/constants";

//handles the rendering of open positions on the screen as per career type selected
const RenderOpenPositions = (props) => {
    return (
        <>
            {props.openPositionValues.map(item => (
                <div key={item.id} className="p-3 shadow bg-white" style={{height: "320px"}}>

                    <div className="flex-fill pl-4">
                        <h5>{item.h5_content}</h5>
                        <h6>{item.h6_content}</h6>
                        <p>{item.paragraph_text}</p>
                        <div className="text-center">
                            <a data-applied={item.data_applied_value}
                               className="vApply d-inline-block mt-3 red-btn rounded text-white text-decoration-none"
                               style={{cursor: "pointer"}}>Apply</a>
                        </div>
                    </div>

                </div>
            ))}
        </>
    )
}

// handles the overall rendering of elements on the screen as per career type selected
const RenderElementOnScreen = (props) => {
    return (
        <section className="py-5">
            <div className="container">
                <div className="row  text-center wow zoomIn">
                    <div className="col-md-12">
                        <h1>Open Positions</h1>
                    </div>
                </div>
                <div className="row py-5 wow zoomIn">

                    {/*displays the open positions in carousel form*/}
                    {/* items = if the screen size is of mobile then only 1 item is rendered
                    otherwise 3 items will render on the screen*/}
                    <OwlCarousel className='owl-theme' items={props.isMobile ? 1 : 3} nav>

                        {/*handles the rendering of open positions for selected career type */}
                        <RenderOpenPositions
                            openPositionValues = {props.openPositionValues}
                        />
                    </OwlCarousel>

                </div>
            </div>
        </section>

    )
}

function OpenPositions(props) {

    //true if the selected career type by the user is full time expert
    if (props.CareerType === "fullTimeExpert") {
        return (
            <RenderElementOnScreen
                openPositionValues = {openPositionValuesFullTimeExpert}
                isMobile={props.isMobile}
            />
        )
    } else
        return (
            <RenderElementOnScreen
                openPositionValues = {openPositionValuesFreelancing}
                isMobile={props.isMobile}
            />
        )
}

export default OpenPositions
