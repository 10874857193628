import React from 'react'

import {
    career_information_list_freelancing,
    career_information_list_fullTimeExpert
} from '../../utils/constants'

const RenderImage = ({item}) => {
    return (
        <img
            src={item.image_src}
            alt="schools"
            className="img-fluid"
            style={{marginTop: "-70px"}}/>
    )
}

const SideImages = () => {
    return (
        <>
            <img src="images/bg-5.png" alt="Side_Image" className="bg-5"/>
            <img src="images/bg6.png" alt="Side_Image" className="bg6"/>
        </>
    )
}

const RenderPara = ({item}) => {
    return (
        <>
            {
                item.written.map(para => (
                    <p className={item.color_white ? "text-white" : ""}>
                        {para.p1}
                        <b>{para.b1}</b>
                        {para.p2}
                    </p>
                ))
            }
        </>
    )
}
const RenderWritten = ({item}) => {
    return (
        <>
            <h1 className={item.color_white ? "text-white" : ""}> {item.written_title} </h1>
            <p><br/></p>
            <RenderPara item={item}/>
        </>
    )
}

const ShowRow = ({item, mobile}) => {

    if (mobile)
        return (
            <div className="row">

                <div className={item.written_class}>
                    <div className="py-5">
                        <h1 className={item.color_white ? "text-white" : ""}> {item.written_title} </h1>
                        <RenderImage item={item}/>
                        <RenderPara item={item}/>

                    </div>
                </div>
            </div>
        )

    else if (item.image_class === "col-md-6 wow slideInLeft")
        return (
            <div className={item.mainDivClass}>

                <div className={item.image_class}>
                    <RenderImage item={item}/>
                </div>

                <div className={item.written_class}>
                    <div className="py-5">
                        <RenderWritten item={item}/>
                    </div>
                </div>

            </div>
        )
    else
        return (
            <div className={item.mainDivClass}>

                <div className={item.written_class}>
                    <div className="py-5">
                        <RenderWritten item={item}/>
                    </div>
                </div>

                <div className={item.image_class}>
                    <RenderImage item={item}/>
                </div>

            </div>
        )
}

const RenderElementOnScreen = (props) => {
    return (
        <section className="py-5 features" style={{backgroundSize: "100% 58%"}}>

            <SideImages/>

            <div className="container">

                {props.career_information_list.map(item => (
                    <ShowRow item={item} mobile={props.isMobile}/>
                ))}

            </div>
        </section>
    )
}

function CareerInformation(props) {

    if (props.CareerType === "fullTimeExpert" ){
        return(
            <RenderElementOnScreen
                career_information_list = {career_information_list_fullTimeExpert}
                isMobile = {props.isMobile}
            />
        )
    }
    else
        return(
            <RenderElementOnScreen
                career_information_list = {career_information_list_freelancing}
                isMobile = {props.isMobile}
            />
        )
}

export default CareerInformation
