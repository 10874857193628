import React from 'react'

function Privacy() {
    return (
        <div className="page-banner py-5">
            <div className=" container" style={{padding: "10px 50px 15px 50px"}}>
                <h2>This Privacy Policy applies to iTinker</h2>
                <Intro/>
                <InformationWeCollect/>
                <DifferentWaysOfCollectingInformation/>
                <UseOfPersonalInformation/>
                <SharingWith3rdParties/>
                <Others/>
            </div>
        </div>
    )
}

function Intro() {
    return (
        <>
            <p>iTinker recognises
                the importance of maintaining your privacy. We value your privacy and
                appreciate your trust in us. This Policy describes how we treat user
                information we collect on https://itinker.in and other offline sources. This
                Privacy Policy applies to current and former visitors to our website and to our
                online customers. By visiting and/or using our website, you agree to this
                Privacy Policy.</p>

            <p>iTinker
                is used by Namya Future Technologies India Private Limited, an Indian
                Company registered under the Companies Act, 2013 having its registered office
                at #637, Sector 48/A, Chandigarh - 160047.</p>
        </>
    )
}

function InformationWeCollect() {
    return (
        <>
            <p><strong>Information
                we collect</strong></p>

            <p><strong>Contact
                information.</strong> We might collect your name, email, mobile number, phone
                number, street, city, state, pin code, country and ip address.</p>

            <p><strong>Payment
                and billing information.</strong> We might collect your billing name, billing
                address and payment method when you make a purchase. We NEVER collect your
                credit card number or credit card expiry date or other details pertaining to
                your credit card on our website. Credit card information will be obtained and
                processed by our online payment partner(s).</p>

            <p><strong>Information
                you post.</strong> We collect information you post in a public space on our
                website or on a third-party social media site belonging to iTinker.</p>

            <p><strong>Demographic
                information.</strong> We may collect demographic information about you,
                events you like, events you intend to participate in, products you buy, or any
                other information provided by your during the use of our website. We might
                collect this as a part of a survey also.</p>

            <p><strong>Other
                information.</strong> If you use our website, we may collect information
                about your IP address and the browser you're using. We might look at what site
                you came from, duration of time spent on our website, pages accessed or what
                site you visit when you leave us. We might also collect the type of mobile
                device you are using, or the version of the operating system your computer or
                device is running. </p>
        </>
    )
}

function DifferentWaysOfCollectingInformation() {
    return (
        <>
            <p><strong>We
                collect information in different ways.</strong></p>

            <p><strong>We
                collect information directly from you.</strong> We collect
                information directly from you when you register for an event or buy a product.
                We also collect information if you post a comment on our websites or ask us a
                question through phone or email.</p>

            <p><strong>We
                collect information from you passively.</strong> We use tracking
                tools like Google Analytics, Google Webmaster, browser cookies and web beacons
                for collecting information about your usage of our website. </p>

            <p><strong>We
                get information about you from third parties.</strong> For example, if you
                use an integrated social media feature on our websites. The third-party social
                media site will give us certain information about you. This could include your
                name and email address.</p>
        </>
    )
}

function UseOfPersonalInformation() {
    return (
        <>
            <p><strong>Use
                of your personal information</strong></p>

            <p><strong>We
                use information to contact you: </strong>We might use the information you
                provide to contact you for confirmation of a purchase/event on our website or
                for other promotional purposes.</p>

            <p><strong>We
                use information to respond to your requests or questions.</strong> We might
                use your information to confirm your registration for an event or competition. </p>

            <p><strong>We
                use information to improve our products and services.</strong> We might
                use your information to customize your experience with us. This could include
                displaying content based upon your preferences.</p>

            <p><strong>We
                use information to look at site trends and customer interests.</strong> We may
                use your information to make our website and products better. We may combine
                information we get from you with information about you we get from third
                parties.</p>

            <p><strong>We
                use information for security purposes.</strong> We may use
                information to protect our company, our customers, or our websites.</p>

            <p><strong>We
                use information for marketing purposes.</strong> We might send you
                information about special promotions or offers. We might also tell you about
                new features or products. These might be our own offers or products, or
                third-party offers or products we think you might find interesting. </p>

            <p><strong>We
                use information to send you transactional communications.</strong> We might
                send you emails or SMS about your account or a purchase. </p>

            <p>We use information
                as otherwise permitted by law.</p>
        </>
    )
}

function SharingWith3rdParties() {
    return (
        <>
            <p><strong>Sharing
                of information with third parties</strong></p>
            >
            <p><strong>We
                will share information with third parties who perform services on our behalf. </strong>We share
                information with vendors who help us manage our online registration process or
                payment processors or transactional message processors. Some vendors may be
                located outside of India.</p>

            <p><strong>We
                will share information with the event organizers.</strong> We share your
                information with vendors, event organizers and other parties responsible for
                fulfilling the purchase obligation. The event organizers and other parties may
                use the information we give them as described in their privacy policies. </p>

            <p><strong>We
                will share information with our business partners.</strong> Our partners use
                the information we give them as described in their privacy policies. </p>

            <p><strong>We
                may share information if we think we have to in order to comply with the law or
                to protect ourselves.</strong> We will share information to respond to a
                court order or subpoena. We may also share it if a government agency or
                investigatory body requests. Or, we might also share information when we are
                investigating potential fraud. </p>

            <p><strong>We
                may share information with any successor to all or part of our business.</strong> For example,
                if part of our business is sold, we may give our customer list as part of that
                transaction.</p>

            <p><strong>We
                may share your information for reasons not described in this policy.</strong> We will
                tell you before we do this.</p>
        </>
    )
}

function Others() {
    return (
        <>

            <p><strong>Email
                Opt-Out</strong></p>

            <p><strong>You
                can opt out of receiving our marketing emails.</strong> To stop receiving
                our promotional emails, please email unsubscriber@itinker.in. It may take about
                ten days to process your request. Even if you opt out of getting marketing
                messages, we will still be sending you transactional messages through email and
                SMS about your purchases. <br/>
                <br/>
            </p>

            <p><strong>Third
                party sites </strong></p>

            <p>If you click on one
                of the links to third party websites, you may be taken to websites we do not
                control. This policy does not apply to the privacy practices of those websites.
                Read the privacy policy of other websites carefully. We are not responsible for
                these third-party sites.</p>

            <p><strong>Grievance
                Officer</strong></p>

            <p>In accordance with
                Information Technology Act 2000 and rules made there under, the name and
                contact details of the Grievance Officer are provided below:</p>

            <p>Mr. Sparsh Jain</p>

            <p>#637, Sector 48 A</p>

            <p>Chandigarh - 160047</p>

            <p>Phone: +91 84475 31732</p>

            <p>Email: sparsh@3dwe.in</p>

            <p>If you have any questions about this Policy or other
                privacy concerns, you can also email us at
                <a href="mailto:support@itinker.in">support@itinker.in</a>
            </p>

            <p><strong>Updates
                to this policy</strong></p>

            <p>This Privacy Policy
                was last updated on 10-08-2020. From time to time we may change our privacy
                practices. We will notify you of any material changes to this policy as
                required by law. We will also post an updated copy on our website. Please check
                our site periodically for updates.</p>

            <p><strong>Jurisdiction</strong></p>

            <p>If you choose to visit the website, your visit and any dispute over privacy is subject to this Policy and
                the
                website's <a href="/terms">terms of service</a>. In addition to the foregoing, any disputes arising
                under
                this
                Policy shall be governed by the laws of India. </p>


        </>
    )
}

export default Privacy