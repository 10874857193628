import 'react-responsive-carousel/lib/styles/carousel.min.css'; 
import React , { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Carousel } from 'react-responsive-carousel';
import { PropTypes } from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';

import '../main_style.css'

// to get the thumbnail of the youtube video url 
const youtubeThumbnail = require('youtube-thumbnail');

// style to add to the carousel
const useStyles = makeStyles(theme => ({
    carousel: {
        margin: theme.spacing(2)
    }
}));

// slide of a single you tube video
const YoutubeSlide = ({ url, isSelected, content , Height}) => (
    <>
        {/* title of the youtube video to render per video slide */}
        <h5 style={{ color: "#11246F" }}>
            {content}
        </h5>

        {/* player which runs the youtube video having width, height, url provided as the arguments */}
        <ReactPlayer 
            width="100%" 
            height={Height}
            url={url} 
            playing={isSelected} />

    </>
);

// main function of the page
const CarouselVideo = ({ data }) => {

    // className to pass to carousel
    const classes = useStyles();

    // return the thumbnail of the youtube video whose url is passed to the function
    const getVideoThumb = url => youtubeThumbnail(url).default.url;

    // return the video id of the youtube video whose url is pass as the parameter to the function
    const getVideoId = url => url.substr('https://www.youtube.com/watch?v='.length, url.length);

    // renders the thumbnails of all the videos array passed as the parameter to the function at the
    // bottom part 
    const customRenderThumb = children =>
        children.map(item => {
            // returns videoId of the item passed
            const videoId = getVideoId(item.props.url);

            // returns the thumbnail of the video item
            return <img key={videoId} src={getVideoThumb(item.props.url)} alt="video thumb" />;
        });

    // variable to check is the screen size is of mobile or not
    const [isMobile, setIsMobile] = useState(window.innerWidth < 520);

    // runs whenever the screen size changes and set the value of the isMobile variable accordingly
    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < 1200;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);
    
    return (
            <Grid item md={8} xs={12} style={{margin : "auto"}} >

                {/* it returns the carousel of the you tube videos with thumbnail */}
                <Carousel 
                    autoPlay={false}
                    className={classes.carousel}
                    emulateTouch={true}
                    showArrows={true}
                    showThumbs={true}
                    showStatus={false}
                    infiniteLoop={true}
                    renderThumbs={customRenderThumb}
                >
                    {/* runs for each item in the data send as the props and results 
                    in carousel of the videos */}
                    {data.map(v => (
                        <YoutubeSlide
                            url={v.videoUrl}
                            muted
                            playing={false}
                            key={v._id ? v._id : v.id}
                            content={v.h5_content}
                            Height={`${isMobile ? "276px" : "350px" }`}
                        />
                    ))}

                </Carousel>
            </Grid>
    );
};

// asign the type of the props pass to the YoutubeSlide function
YoutubeSlide.propTypes = {
    url: PropTypes.string,
    isSelected: PropTypes.bool
};

// assign the type of the props pass to the CarouselVideo function
CarouselVideo.propTypes = {
    data: PropTypes.array
};

export default CarouselVideo;