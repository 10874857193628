import React, {useEffect} from 'react'

// import all the data by name of constant
import * as constants from '../../utils/constants'
import TitleScreen from "./title_screen";
import StudentWords from "./student_words";
import MoreFromStudent from "./more_from_student";

// main function which handles the rendering of element on the screen from the student_page
function StudentPage(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // render if the name of the student is Aisha
    if (props.student === "Aisha") {
        return (
            <>
                <SingleChildPage
                    Intro_Video="https://player.vimeo.com/video/473403093"
                    ImageSourceMob="images/Activity/aisha_bg_video_mob.jpg"
                    ImageSource="images/Activity/aisha_video_bg.jpg"
                    values={constants.aisha_values_list}
                    title="Aisha"
                    videos={constants.aisha_videos_list}
                    isMobile={props.isMobile}
                    StudentId={1}
                />
            </>
        )
    }

    // render if the name of the student is Atharv
    else if (props.student === "Atharv")
        return (
            <>
                <SingleChildPage
                    Intro_Video="https://player.vimeo.com/video/479503398"
                    ImageSourceMob="images/Activity/atharv_bg_video_mob.jpg"
                    ImageSource="images/Activity/atharv_video_bg.jpg"
                    values={constants.atharv_values_list}
                    title="Atharv"
                    videos={constants.atharv_videos_list}
                    isMobile={props.isMobile}
                    StudentId={2}
                />
            </>
        )

    // render if the name of the student is Devina
    else if (props.student === "Devina")
        return (
            <>
                <SingleChildPage
                    Intro_Video="https://player.vimeo.com/video/512235386"
                    ImageSourceMob="images/Activity/devina_bg_video_mob.png"
                    ImageSource="images/Activity/devina_bg_video.jpg"
                    values={constants.devina_values_list}
                    isMobile={props.isMobile}
                    StudentId={3}
                />
            </>
        )

    // render if the name of the student is Sagar
    else if (props.student === "Sagar")
        return (
            <>
                <SingleChildPage
                    Intro_Video="https://player.vimeo.com/video/524235793"
                    ImageSourceMob="images/Activity/sagar-bg-self-mob.jpg"
                    ImageSource="images/Activity/sagar-bg-self.jpg"
                    values={constants.sagar_values_list}
                    isMobile={props.isMobile}
                    StudentId={4}
                />
            </>
        )
}


// handles the rendering of student's page
function SingleChildPage(props) {
    return (
        <div>
            {/* renders the title video and image of particular student on the screen */}
            <TitleScreen
                Intro_Video={props.Intro_Video}
                ImageSourceMob={props.ImageSourceMob}
                ImageSource={props.ImageSource}
                isMobile={props.isMobile}/>

            {/* render the students project and there views on the screen */}
            <StudentWords values={props.values} isMobile={props.isMobile} id={props.StudentId}/>

            {/* render the videos of particular student on the screen */}
            {typeof props.title === 'undefined' ? " " :
                <MoreFromStudent
                    title={props.title}
                    videos={props.videos}/>
            }
        </div>
    )
}


export default StudentPage
