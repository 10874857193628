import React from 'react'

// handles the rendering of written content of our impact part of the page
function OurImpactWrittenContent() {
    return (
        <>
            <h3 id="h33"><strong><span id="p1">Technology is changing the world.</span></strong></h3>
            <p id="p1"><span id="p1">Support for Alzheimer's' patients, domestic violence, climate
                                            change, the impact of COVID-19 – these are just a few  problems 1,500
                                            Technovation Girls teams around the world tackled in 2020.</span></p>
            <h3 id="h33"><strong><span id="p1">You can join Us.</span></strong></h3>
            <p id="p1"><span id="p1">Our global community of students, mentors, ambassadors, parents
                                            and judges are curious, creative, and determined to change the world using
                                            technology. Most join with no previous tech experience.</span></p>
            <p id="p1"><span id="p1">Learn how to code a mobile app that solves a problem you care
                                            about – or support a team that does. </span></p>
        </>
    )
}

// handles the rendering of another written content of our impact part of the page
function OurImpactWritten(props) {

    // renders if the screen size is of mobile otherwise else part is rendered
    if (props.isMobile)
        return (
            <>
                <img src="images/technovation/Alumni_Interests_Pie.png"
                     alt="schools"
                     className="img-fluid"
                     style={{marginTop: "100px"}}/>
                <div className="py-5">
                    <h2 id="h22">
                        <p id="p1"><span>After participating in Technovation Girls ....</span></p>
                    </h2>
                    <p id="p1">
                        <span id="p1">
                            Students express a
                            <strong> greater interest in technology and leadership</strong>
                            after participating in our program, and
                             <strong>58% of our alumni</strong> enroll in
                            <strong>more computer science courses</strong> after Technovation.
                        </span>
                    </p>
                    <OurImpactLinksRendering/>
                    <a href="https://www.technovation.org/impact/"
                       target="_blank"
                       rel="noreferrer"
                       className="fl-button"
                       role="button">
                        <span id="b1"
                              className="fl-button-text btn btn-warning">See our impact</span>
                    </a>
                </div>
            </>
        )
    else
        return (
            <>
                <p id="p1"><span id="p1">
                                After participating in Technovation Girls ....</span></p>
                <p id="p1">Students express a greater
                    interest in technology and leadership after participating
                    in our program, and 58% of our alumni enroll in
                    more computer science courses after Technovation.
                </p>
                <OurImpactLinksRendering/>
            </>
        )
}

// handles the rendering of links of our impact part of the page
function OurImpactLinksRendering() {
    return (
        <>
            <p id="p1">
                <span id="p1">That's not all – alumni go on to start their own businesses, present at
                    <a href="https://www.youtube.com/watch?v=U8hHxhB7yOs" target="_blank"
                       rel="noreferrer" > prestigious
                    </a>
                    <a href="https://www.whitehouse.gov/blog/2016/04/08/science-fair-2016-meet-next-generation-americas-innovators"> events
                    </a>
                    ,
                    <a href="http://www.ndtv.com/bangalore-news/pm-narendra-modi-lauds-bengaluru-girls-for-app-to-implement-1203918"
                       target="_blank"
                       rel="noreferrer"> meet
                    </a>
                    <a href="https://mywitin.blogspot.com/2013/09/first-lady-celebrates-2013-winners-of.html"
                       rel="noreferrer"
                       target="_blank" > world
                    </a>
                    <a href="https://youtu.be/op9VlUfYUtI?t=29s" target="_blank"
                       rel="noreferrer"> leaders
                    </a>
                    , and even return to
                    <a href="http://technovation.staging.wpengine.com/girls-change-the-world/"
                       target="_blank"
                       rel="noreferrer" > support the next cohorts
                    </a> of Technovation Girls.
                </span>
            </p>
            <p id="p1">
                <span id="p1">Technovation Girls is just the beginning.</span>
            </p>
        </>
    )
}

// handles the rendering of our impact part of the page
function OurImpact(props) {
    return (
        <section className={props.isMobile ? "py-5 orange-bg mob-sec text-center" : "py-5 orange-bg web-sec"}
                 style={props.isMobile ? {backgroundSize: "300% 46%"} : {}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 wow slideInLeft">
                        <img src="images/scl-stu.png"
                             alt="schools"
                             className="img-fluid"
                             style={{marginTop: "-40px"}}/>
                    </div>
                    <div className="col-md-6 wow slideInRight">
                        <div className="py-5">
                            <h1 id="h11">Change the world together</h1>

                            {/*renders the function OurImpactWrittenContent in div if the
                            screen is of mobile size otherwise directly renders the function */}
                            {props.isMobile
                                ?
                                <div className="fl-rich-text">
                                    <OurImpactWrittenContent/>
                                </div>
                                :
                                <OurImpactWrittenContent/>
                            }

                            <a href="https://rb.gy/piiebw" target="_blank"
                               rel="noreferrer"
                               className="fl-button"
                               role="button">
                                <span id="b1" className="fl-button  btn btn-dark">Sign Up Today!</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div className={props.isMobile ? "row" : "row pt-5"}>

                    <div className={props.isMobile ? "col-md-6 wow slideInLeft" : "col-md-5 wow slideInLeft"}>
                        <div className="py-5">
                            <h1 id="h11"
                                style={props.isMobile ? {fontSize: "38px"} : {}}>
                                Technovation Changes Students' Lives
                            </h1>

                            <OurImpactWritten isMobile={props.isMobile}/>

                        </div>
                    </div>

                    {/*renders only if the screen size is not of mobile */}
                    {props.isMobile
                        ?
                        <></>
                        :
                        <>
                            <div className="col-md-7 wow slideInRight">
                                <img src="images/technovation/Alumni_Interests_Pie.png" alt="schools"
                                     className="img-fluid"
                                     style={{marginTop: "100px"}}/>
                            </div>
                            <a href="https://www.technovation.org/impact/" target="_blank" className="fl-button"
                               rel="noreferrer"
                               role="button">
                                <span id="b1" className="fl-button-text btn btn-warning">See our impact</span>
                            </a>
                        </>
                    }

                </div>
            </div>
        </section>
    )
}

export default OurImpact