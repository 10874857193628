import React from 'react'
import * as emailjs from 'emailjs-com'


// const submitFormHandler = (e) => {
//     e.preventDefault();
//     axios({
//         method: "POST",
//         url:"/send",
//         data:  this.state
//     }).then((response)=>{
//         if (response.data.status === 'success'){
//             alert("Message Sent.");
//             // this.resetForm()
//         }else if(response.data.status === 'fail'){
//             alert("Message failed to send.")
//         }
//     })
// }


class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            represent: '',
            message: ''
        }
    }

    handleChange = (param, e) => {
        this.setState({[param]: e.target.value})
    }

    // submitEmail(e) {
    //     e.preventDefault();
    //     axios({
    //         method: "POST",
    //         url: "/send",
    //         data: this.state
    //     }).then((response) => {
    //         if (response.data.status === 'success') {
    //             alert("Message Sent.");
    //             this.resetForm()
    //         } else if (response.data.status === 'fail') {
    //             alert("Message failed to send.")
    //         }
    //     })
    // }

    handleSubmit(e) {
        e.preventDefault();

        emailjs.sendForm(
            'service_zxdcxcq',
            'template_t4ngbag',
            'contactForm',
            'user_81IFZTyz3TNPby2hARUO5'
        ).then(function (response) {
            console.log('SUCCESS!', response.status, response.text);
        }, function (error) {
            alert('failed');
            console.log('FAILED...', error);
        });

        this.resetForm()
    }


    resetForm() {
        this.setState({name: '', email: '', subject: '', message: ''})
    }

    render() {
        return (
            <section>

                <img src="images/bg3.png" alt="form_image" className="bg3"/>

                <div className="container">
                    <div className="row">
                        <div className="col-md-10 md-offset-1 pb-5">
                            <div className="shadow w-100">
                                <form id="contactForm"
                                      onSubmit={this.handleSubmit.bind(this)}
                                      action="#"
                                      method="POST"
                                      className="w-75 py-5"
                                      noValidate>
                                    <div className="row">

                                        <div className="col-md-6">

                                            <div className="form-group ">
                                                <label htmlFor="name"><b>Name: *</b></label>
                                                <input type="text"
                                                       className="form-control"
                                                       value={this.state.name}
                                                       onChange={this.handleChange.bind(this, 'name')}
                                                       id="name" placeholder="John Doe"
                                                       name="name" required/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="email"><b>Email: *</b></label>
                                                <input type="email"
                                                       className="form-control"
                                                       id="email"
                                                       value={this.state.email}
                                                       onChange={this.handleChange.bind(this, 'email')}
                                                       placeholder="jdoe@gmail.com"
                                                       name="email" required/>
                                            </div>
                                            <div className="form-group ">
                                                <label htmlFor="phone"><b>Phone: *</b></label>
                                                <input type="text"
                                                       className="form-control"
                                                       id="contact"
                                                       value={this.state.phone}
                                                       onChange={this.handleChange.bind(this, 'phone')}
                                                       data-parsley-type="digits"
                                                       data-parsley-maxlength="10" data-parsley-minlength="10"
                                                       placeholder="9XXXXXXXXX" name="contact" required/>
                                            </div>


                                        </div>

                                        <div className="col-md-6">

                                            <div className="form-group">
                                                <label htmlFor="sel1"><b>I represent*</b></label>
                                                <select className="form-control"
                                                        id="sel1"
                                                        value={this.state.represent}
                                                        onChange={this.handleChange.bind(this, 'represent')}
                                                        name="sel1" required>

                                                    {/* handles the option for i_represent */}


                                                    <option/>
                                                    <option value="school">School</option>
                                                    <option value="expert">Expert</option>
                                                    <option value="donor">Donor</option>


                                                </select>
                                            </div>

                                            {/* the column to add message by the user */}
                                            <div className="form-group">
                                                <label htmlFor="msg"><b>Message: *</b></label>
                                                <textarea
                                                    type="text"
                                                    rows="8"
                                                    className="form-control"
                                                    id="message"
                                                    value={this.state.message}
                                                    onChange={this.handleChange.bind(this, 'message')}
                                                    placeholder="Your Message"
                                                    name="message"
                                                    required/>
                                            </div>

                                        </div>


                                    </div>

                                    <div className="text-center">

                                        {/* the submit button */}
                                        <input
                                            type="submit"
                                            name=""
                                            className="btn text-white view-more border-0"
                                            value="Submit"/>

                                    </div>

                                    <div className="text-center" style={{marginTop: "20px"}}>

                                        {/* the message to display to user when succeeded in form submission */}
                                        <div id="success"
                                             style={{display: "none", color: "green"}}>
                                            Thank you for your interest. We will be in touch with you soon.
                                        </div>

                                        {/* message to render when the user fails to submit the form */}
                                        <div id="error"
                                             style={{display: "none", color: "red"}}>
                                            Problem submitting your request, Please try again later.
                                        </div>

                                    </div>

                                </form>
                            </div>

                        </div>
                    </div>
                </div>

                <img src="images/arrow.png" alt="arrow" className="arrow"/>
                <img src="images/bg6.png" alt="bg" className="bg6" style={{zIndex: "0", top: "0"}}/>

            </section>
        );
    }

}

// export default QueryForm
export default Contact
