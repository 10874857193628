import React from 'react'
import { NavLink } from "react-router-dom";
import './main_style.css'

function Header(props) {
    return (
        <header>
            <nav className="navbar navbar-expand-lg navbar-light fixed-top menu-color"
                style={{ marginLeft: "-47px" }} >

                <div className="container-fluid">

                    {/* company logo  */}
                    <NavLink exact to="/">
                        <img className="logo_image" alt="logo" src="images/logo.png" />
                    </NavLink>

                    {/* hamburger button on mobile view */}
                    <button className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation">

                        <span className="navbar-toggler-icon"/>

                    </button>

                    {/* handles rendering to different pages  */}
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-0">
                            <li className={props.isMobile ? "nav-item navbar-toggler" : "nav-item "}
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent">
                                <div className="nav-link p-3 px-md-4 px-sm-0 font-weight-bolder" >
                                    <NavLink activeClassName="is-active" exact to="/">
                                        Home
                                    </NavLink>
                                </div>
                            </li>
                            <li className={props.isMobile ? "nav-item navbar-toggler" : "nav-item "}
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent">
                                <div className="nav-link p-3 px-md-4 px-sm-0 font-weight-bolder" >
                                    <NavLink activeClassName="is-active" exact to="/features" >
                                        Features
                                    </NavLink>
                                </div>
                            </li>
                            <li className={props.isMobile ? "nav-item navbar-toggler" : "nav-item "}
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent">
                                <div className="nav-link p-3 px-md-4 px-sm-0 font-weight-bolder" >
                                    <NavLink activeClassName="is-active" exact to="/schools" >
                                        For Schools
                                    </NavLink>
                                </div>
                            </li>
                            <li className={props.isMobile ? "nav-item navbar-toggler" : "nav-item "}
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent">
                                <div className="nav-link p-3 px-md-4 px-sm-0 font-weight-bolder" >
                                    <NavLink activeClassName="is-active" exact to="/tinkerWorld" >
                                        Tinker World
                                    </NavLink>
                                </div>
                            </li>
                            <li className={props.isMobile ? "nav-item navbar-toggler" : "nav-item "}
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent">
                                <div className="nav-link p-3 px-md-4 px-sm-0 font-weight-bolder" >
                                    <NavLink activeClassName="is-active" exact to="/about" >
                                        About Us
                                    </NavLink>
                                </div>
                            </li>
                            <li className={props.isMobile ? "nav-item navbar-toggler" : "nav-item "}
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent">
                                <div className="nav-link p-3 px-md-4 px-sm-0 font-weight-bolder" >
                                    <NavLink activeClassName="is-active" exact to="/technovation" >
                                        Technovation
                                    </NavLink>
                                </div>
                            </li>
                            <li className= "nav-item dropdown "
                                >
                                <a className="dropbtn nav-link p-3 px-md-4 px-sm-0 font-weight-bolder"
                                style={{color : "#666"}}>Careers&nbsp;&nbsp;
                                    <i className="fa fa-chevron-down" aria-hidden="true" style={{color : "#666"}}/></a>
                                <div className={props.isMobile ? "dropdown-content navbar-toggler" : "dropdown-content dropdown "}
                                     data-toggle="collapse"
                                     data-target="#navbarSupportedContent"
                                     aria-controls="navbarSupportedContent">
                                    <NavLink activeClassName="is-active" exact to="/fullTimeExpert" >
                                        Full time Experts
                                    </NavLink>
                                    <NavLink activeClassName="is-active" exact to="/freelancing" >
                                        Freelancers
                                    </NavLink>
                                </div>
                            </li>

                            <li className={props.isMobile ? "nav-item navbar-toggler" : "nav-item "}
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent">
                                <div className="nav-link p-3 px-md-4 px-sm-0 font-weight-bolder" >
                                    <NavLink activeClassName="is-active" exact to="/contactUs" >
                                        Contact
                                    </NavLink>
                                </div>
                            </li>

                            <li className={props.isMobile ? "nav-item navbar-toggler" : "nav-item "}
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent">
                                <a href="https://my.itinker.in/wp-login.php?redirect_to=https%3A%2F%2Fmy.itinker.in%2F&bp-auth=1"
                                   className="nav-link p-3 px-md-4 px-sm-0 font-weight-bolder login rounded">
                                        Login
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header;
