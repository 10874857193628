/* **************************************home **************************************** */

//slideshow page

// content of the slides 
export const slides = [
    //id --> id of the object, web_img --> image to render on view other than mobile view,
    // mob_img --> image to render on mobile view, 
    //redirectNotAvailable --> is the button to redirect is avaialable or not,
    //redirect --> link to redirect on request
    {
      "id": "0", "web_img": "images/ActivitiesBanner/itinker-main.jpg", 
      "mob_img": "images/ActivitiesBanner/itinker-main-mob.jpg",
      "redirectNotAvailable" : "1",
      "redirect": ""
    }, 
    {
      "id": "1", "web_img": "images/Activity/aisha_bg.jpg",
      "mob_img": "images/Activity/aisha_bg_mob.jpg",
      "redirect": "aboutAisha"
    }, 
    {
      "id": "2", "web_img": "images/Activity/atharv_bg.jpg",
      "mob_img": "images/Activity/atharv_bg_mob.jpg",
      "redirect": "aboutAtharv"
    }, 
    {
      "id": "3", "web_img": "images/Activity/devina_bg.jpg",
      "mob_img": "images/Activity/devina_bg_mob.jpg",
      "redirect": "aboutDevina"
    }, 
    {
      "id": "4", "web_img": "images/Activity/sagar-bg-main.jpg",
      "mob_img": "images/Activity/sagar-bg-main-mob.jpg",
      "redirect": "aboutSagar"
    },
  ];

// for_school page

// array having values for 'iTinker For Schools'
export const ForInfo = [
    // id --> id of the object, title --> title of the content, text --> written content for the title
    {
        "id" :"0",
        "title" : "For students",
        "text" : " - A personalized activity oriented learning path to advance at their own pace, " +
            "industry collaboration to apply their skills and competitions to win exciting prizes daily",
    },
    {
        "id" :"1",
        "title" : "For teachers/mentors",
        "text" : " - Periodic certifications to keep abreast of latest developments, " +
            "online/in-person meetups to learn from one another",
    },
    {
        "id" :"2",
        "title" : "For administrators",
        "text" : " - School leaderboards to measure progress in real-time, " +
            "integrated e-commerce shop & custom reports to help students improve their skills",
    },
]


// i_represent page

// array having values for 'i_represent' 
export const represent = [
    //id --> id of the object, ImageSrc --> image to render, redirect --> page to render on click
    // a_text --> the text of link to redirect
    {
        "id" : "0",
        "ImageSrc" : "images/ima4.svg",
        "redirect" : "/schools",
        "a_text" : "A School",
    },
    {
        "id" : "1",
        "ImageSrc" : "images/ima3.svg",
        "redirect" : "/fullTimeExpert",
        "a_text" : "An Expert",
    },
]

//partner_schools page
// array having values to render
export const schools = [
    // id --> id of the object, link --> link of the school to render on click of image
    // title --> title or name of the school, sourceImg --> image of the school

    {
        "id": "0",
        "link": "https://www.ypspatiala.in/view_news_detail.php?id=161",
        "title": "Yadavindra Public School Patiala",
        "SourceImg": "images/schools/yps.png",
    },
    {
        "id": "1",
        "link": "http://hansrajpublicschool.com/",
        "title": "Hansraj Public School Panchkula",
        "SourceImg": "images/schools/hansraj.png",
    }
]

//platform features page

// constant having vlaues of all the features available
export const platform_features = [
    //id --> id of the object, className --> class to pass for each feature
    // imageSrc --> address of the location where image of the feature is stored
    // heading --> heading of the feature, text --> tyext of the feature
    {
        "id": "0",
        "className": "feature1 c-mr p-4",
        "imageSrc": "images/learning.svg",
        "heading": "Future technologies",
        "text": "Artificial Intelligence, Additive Manufacturing, Internet of Things (IoT), " +
            "Drones, AR/VR, Game Development & much more..."
    },
    {
        "id": "1",
        "className": "feature2 c-mr p-4",
        "imageSrc": "images/joystick.svg",
        "heading": "Gamification",
        "text": "10 exciting game levels of self-paced, personalised learning along with numerous DIY activities",
    },
    {
        "id": "2",
        "className": "feature3 c-mr p-4",
        "imageSrc": "images/head.svg",
        "heading": "Blended pedagogy",
        "text": "Learning, Competitions, Certifications & Collaboration activities both online and in-person"
    },
    {
        "id": "3",
        "className": "feature1 c-mr p-4",
        "imageSrc": "images/training.svg",
        "heading": "Social learning",
        "text": "Knowledge sharing with one another and with students from all over India in an open and safe environment"
    },
];

//team page

// content of the team members
export const home_team = [
    // id --> id of the object, ImageSrc --> location of the image to render
    // h5_content --> content to render on h5 tag (mostly the name of the member)
    // h6_content --> content to render on h6 tag (mostly the position or country of the member)
    // link --> the url of the linkdeIn account or any other of the member
    {
        "id": "0",
        "ImageSrc": "images/henning.jpg",
        "h5_content": "Henning Kosmack (Germany)",
        "h6_content": "Member, Advisory Board",
        "link": "https://www.linkedin.com/in/henningkosmack/",
    },
    {
        "id": "1",
        "ImageSrc": "images/jean.png",
        "h5_content": "Jean Gordon (Finland)",
        "h6_content": "Member, Advisory Board",
        "link": "https://www.linkedin.com/in/jean-gordon-3a193213/",
    },
    {
        "id": "2",
        "ImageSrc": "images/tps_Waraich.jpg",
        "h5_content": "Maj Gen TPS Waraich VSM**",
        "h6_content": "Member, Advisory Board",
        "link": "https://www.linkedin.com/in/major-general-tps-waraich-07872879",
    },
]


/* ******************************************************************************* */



/* *************************************** features *********************************** */

//features_list page
//content of the features list to render on the screen
export const FeaturesList = [
    [
        "Flexible course path",
        "Minimal theory",
        "Concepts explained via stories",
        "Future technologies",
        "Daily Quizzes & Awards",
        "Personalized pace of learning",
        "Progress Maps",
        "Achievement badges",
        "Real-life challenges",
    ],
    [
        "Expert sessions in school",
        "Online how-to lectures",
        "Real-time activity feed",
        "Private Messaging",
        "Local/State/National groups",
        "Interest Based Groups",
        "School leaderboards",
        "Student leaderbords",
        "Local/State/National events",
    ],
    [
        "Industry visits",
        "Industry collaboration",
        "Mentorships",
        "Integrated e-commerce",
        "High-quality products",
        "Cryptocurrency",
        "Scholarships & internships",
        "Productization support",
        "Local meetups",
    ]
]


// platfrom_features page

//content of the elements of the features to render on the screen
export const PlatformFeaturesList = [
    //each array has the content to render per row
    [
        //each object has the content of single feature to render
        {
            "id": "0",
            "className": "feature1 c-mr p-4",
            "image": "images/joystick.svg",
            "title": "Gamification",
            "text": [
                "10 Game Levels",
                "Interactive Leaderboards",
                "Badges & Awards DAILY"
            ]
        },
        {
            "id": "1",
            "className": "feature2 c-mr p-4",
            "image": "images/training.svg",
            "title": "Social learning",
            "text": [
                "Activity Feed",
                "Social Groups (upto National Level)",
                "Variety of Media (Text,Images, Videos)"
            ]
        },
        {
            "id": "2",
            "className": "feature3 c-mr p-4",
            "image": "images/head.svg",
            "title": "Blended pedagogy",
            "text": [
                "Online & Offline Expert Sessions",
                "Real world Challenges",
                "Scholarships & Internships"
            ]
        }
    ],
    [
        {
            "id": "0",
            "className": "feature3 f3 c-mr p-4",
            "image": "images/trophy.svg",
            "title": "Events & Competition",
            "text": [
                "Daily Quizzes",
                "Local Meetups",
                "Local/Regional/National Competitions"
            ]
        },
        {
            "id": "1",
            "className": "feature1 c-mr p-4",
            "image": "images/industrial-robot.svg",
            "title": "Industry-Academia collaboration",
            "text": [
                "Industry Visits for ALL Students passing Level 4",
                "Mentorships",
                "Productization Support"
            ]
        },
        {
            "id": "2",
            "className": "feature2 c-mr p-4",
            "image": "images/shopping.svg",
            "title": "Integrated E-Commerce",
            "text": [
                "Easy & Cheap procurement",
                "Buy Piecewise or in Bulk",
                "Compliant with Govt. Guidelines"
            ]
        }
    ]
];


/* ******************************************************************************* */



/* *************************************** schools *********************************** */

//itinker_for page

// data to render on the page

// id --> id of the object, 
//class --> class of the div having both image and written content in full screen mode,
//firstToRender --> element to render first in full screen (image or written content),
//class_mob --> class of div which having title, image and written content in mobile view,
// title --> title of the row, headingClass --> class to add to title and written content,
//BeforeContent --> is the content is in the formm of text and title in object or before ,
// title, after in object
//image --> object having class to add to div having image(class) , image to render(ImageSource),
// div --> object having information of written content, class to add (class), data to render(data)
export const ElementsToRender = [
    {
        "id": "0",
        "class": "row",
        "firstToRender": "image",
        class_mob: "",
        title: "Students",
        headingClass: "",
        "image": {
            "class": "col-md-6 wow slideInLeft",
            ImageSource: "images/scl-stu.png",
        },
        "div": {
            "class": "col-md-6 wow slideInRight",
            data: [
                {
                    "id": "0",
                    "title": "Gamified learning path",
                    "text": " with both offline and online learning, so that students can learn" +
                        " at their own individual pace and get rewarded throughout the journey",
                },
                {
                    "id": "1",
                    "title": "Social learning options",
                    "text": " so that students can learn from their one another, get recognized by their peers," +
                        " teachers and industry and see how they stack up against others from across the country",
                },
                {
                    "id": "2",
                    "title": "Industry collaboration",
                    "text": " so that students can connect the dots easily between concepts and commercialization" +
                        " and get mentorship & support to create and sell their own products/services",
                },
            ],
        },
    },
    {
        "id": "1",
        "class": "row pt-5",
        "firstToRender": "div",
        class_mob: "py-5",
        title: "Teachers",
        headingClass: "",
        "image": {
            "class": "col-md-6 wow slideInRight",
            ImageSource: "images/scl-teachers.png",
        },
        "div": {
            "class": "col-md-6 wow slideInLeft",
            BeforeContent: '1',
            data: [
                {
                    "id": "0",
                    "title": "Dedicated certifications",
                    "before": "",
                    "after": ", delivered periodically so that teachers can keep their own skills up to date" +
                        " and impart them to students",
                },
                {
                    "id": "1",
                    "title": "Online groups",
                    "before": "",
                    "after": " with access to other teachers/mentors across the country to share issues and" +
                        " ideas, plus access to interest based groups on a variety of topics",
                },
                {
                    "id": "2",
                    "title": "socialization events",
                    "before": "Periodic ",
                    "after": " with local/regional peers to learn from one another and build a strong" +
                        " learning community",
                },
                {
                    "id": "3",
                    "title": "progress reports",
                    "before": "Individualized ",
                    "after": " on school students to review their progress and guide them forward",
                },
            ],
        },
    },
    {
        "id": "2",
        "class": "row pt-5",
        "firstToRender": "image",
        class_mob: "py-2",
        title: "School Administrators",
        headingClass: "text-white",
        "image": {
            "class": "col-md-6 wow slideInLeft",
            ImageSource: "images/scl-com.png",
        },
        "div": {
            "class": "col-md-6 wow slideInRight",
            data: [
                {
                    "id": "0",
                    "title": "Full time expert & success manager",
                    "text": ", to take full ownership and accountability for making the school ATL" +
                        " initiative successful",
                },
                {
                    "id": "1",
                    "title": "Integrated e-shop",
                    "text": " for all relevant products, so as to enable procurement of any combination of" +
                        " products, in small batches or in bulk",
                },
                {
                    "id": "2",
                    "title": "Periodic competitions & school leaderboards",
                    "text": ", at local/regional/national level, so that schools can see where they stand " +
                        "in relation to their peers",
                },
                {
                    "id": "3",
                    "title": "Periodic expert sessions",
                    "text": " delivered in school, so as to motivate students into tinkering",
                },
            ],
        },
    },
    {
        "id": "3",
        "class": "row",
        "firstToRender": "div",
        class_mob: "py-3",
        headingClass: "text-white",
        title: "Alumini",
        "image": {
            "class": "col-md-6 wow slideInRight",
            ImageSource: "images/scl-alumini.png",
        },
        "div": {
            "class": "col-md-6 wow slideInLeft",
            data: [
                {
                    "id": "0",
                    "title": "School groups",
                    "text": " where alumni can collaborate with school on various topics",
                },
                {
                    "id": "1",
                    "title": "Special projects",
                    "text": " where alumni can mentor students to improve their skills",
                },
            ],
        },
    },
]

/* ******************************************************************************* */


/* ***************************** tinker world ************************************ */

// hall_of_fame page

export const DataToRender = [
    // id--> id of the object, rowClass --> class of the row (image + written content) ,
    // firstToRender --> what to render first image or written content,
    // image (object) :-- classN_web --> class to add, src --> source image to render
    // div (object) :-- classN_web --> class to add, b1, p1, p2, p3, ... --> written content
     
    {
        "id": "0",
        rowClass: "row",
        firstToRender: "image",
        image: {
            classN_web: "col-md-6 wow slideInLeft",
            src: "images/scl-stu.png",
        },
        div: {
            classN_web: "col-md-6 wow slideInRight",
            b1: "iTinker ",
            p2: "is proud to present to you hall of fame for our star achievers. We here at" +
                " iTinker firmly believe in the words that ",
            b2: " People often mistake technology for a static picture.It's less like a picture" +
                " and more like a movie. It's the velocity of technology innovation that matters. It's the acceleration.",
            p3: "We make sure our students are familiarized with all the futuristic technologies" +
                " that surely are going to need future ready children!"
        },
    },
    {
        "id": "1",
        rowClass: "row pt-5",
        firstToRender: "div",
        image: {
            classN_web: "col-md-6 wow slideInRight",
            src: "images/scl-teachers.png",
        },
        div: {
            classN_web: "col-md-6 wow slideInLeft",
            pClass: "text-white",
            p1: "To name a few Augmented and Virtual reality, Internet of things, AI, Coding," +
                " Robotics and what not. It's very much apt to say that",
            b1: '"Future belongs to those who can learn, unlearn and relearn things"',
            p2: ". Programs from",
            b2: "iTinker make students fall in love with learning",
            p3: "We help the students gain practical knowledge. We teach them how to develop" +
                " projects like digital games, snapchat filters, AI based detectors and many" +
                " more such amazing projects. Enroll your child now at iTinker to equip them for the" +
                " technology besieged future!",
        },
    },
]

// videos library page

// content of the youtube videos to render in the carousel as per the carousel setup
export const tinkerWorldVideos = [
    //id --> id of the object, videoUrl --> url of the youtube video, 
    //h5_content --> title to render as per video
    {
        "id": "0",
        videoUrl: "https://www.youtube.com/embed/rmGv6GY6rcs",
        "h5_content": "Devina Guleria - Mobile FootBall Game App",
    },
    {
        "id": "1",
        videoUrl: "https://www.youtube.com/embed/lGR-0wRhVSc",
        "h5_content": "Atharv Kansal - Weather Reporting Station",
    },
    {
        "id": "2",
        videoUrl: "https://www.youtube.com/embed/0zA7BIEjfgE",
        "h5_content": "Arnav Aggarwal - Starry Days",
    },
    {
        "id": "3",
        videoUrl: "https://www.youtube.com/embed/sSp9yRWLA_Q",
        "h5_content": "Atharv Shastri - Spy Alarm",
    },
    {
        "id": "4",
        videoUrl: "https://www.youtube.com/embed/hEL8yvmmZbY",
        "h5_content": "Shresth Tandon - Prism Face AR",
    },
    {
        "id": "5",
        videoUrl: "https://www.youtube.com/embed/Gx8TBT1NWoE",
        "h5_content": "Dev Malhotra - Digital Fidget Spinner",
    },
    {
        "id": "6",
        videoUrl: "https://www.youtube.com/embed/MVOeSqHuZCs",
        "h5_content": "Kanav Bansal - Ultrasonic Radar",
    },
    {
        "id": "7",
        videoUrl: "https://www.youtube.com/embed/YLEreWcESto",
        "h5_content": "Sagar Soni - AI Based Logo Detector",
    },
    {
        "id": "8",
        videoUrl: "https://www.youtube.com/embed/axy857_36B4",
        "h5_content": "Atharv Sharma - Object Detector",
    },
    {
        "id": "9",
        videoUrl: "https://www.youtube.com/embed/V8uqspNPVdQ",
        "h5_content": "Arnav Aggrawal - App Controlled Lights",
    },
    {
        "id": "10",
        videoUrl: "https://www.youtube.com/embed/mrCyWXTUf4c",
        "h5_content": "Krishiv Bansal - 3D Printed Photo",
    },
    {
        "id": "11",
        videoUrl: "https://www.youtube.com/embed/42CjgLkw7j0",
        "h5_content": "Devina Guleria - AR Snapchat Mask",
    },
]






/* ******************************************************************************* */


/* ***************************** about us ************************************ */

//advisory board page

//content of the team members to render 
export const AdvisoryBoardTeam = [
    {
        "id": "0",
        "ImageSrc": "images/henning.jpg",
        "h5_content": "Henning Kosmack",
        "h6_content": "(Germany)",
        "link": "https://www.linkedin.com/in/henningkosmack/",
    },
    {
        "id": "1",
        "ImageSrc": "images/jean.png",
        "h5_content": "Jean Gordon",
        "h6_content": "(Finland)",
        "link": "https://www.linkedin.com/in/jean-gordon-3a193213/",
    },
    {
        "id": "2",
        "ImageSrc": "images/jonny.jpg",
        "h5_content": "Jonny Pellegrino",
        "h6_content": "(Spain)",
        "link": "https://www.linkedin.com/in/jonny-pellegrino-79455015/",
    },
    {
        "id": "3",
        "ImageSrc": "images/elina.png",
        "h5_content": "Elina Spruck",
        "h6_content": "(Germany)",
        "link": "https://www.linkedin.com/in/elina-spruck-91a3a623/",
    },
    {
        "id": "4",
        "ImageSrc": "images/gabor.jpg",
        "h5_content": "Gabor Ritter",
        "h6_content": "(Hungary)",
        "link": "https://www.linkedin.com/in/g%C3%A1bor-ritter-05a01677/",
    },
    {
        "id": "5",
        "ImageSrc": "images/tps_Waraich.jpg",
        "h5_content": "Maj Gen TPS Waraich VSM**",
        "h6_content": "Director, YPS Mohali",
        "link": "https://www.linkedin.com/in/major-general-tps-waraich-07872879",
    },
    {
        "id": "6",
        "ImageSrc": "images/dr_meena.jpg",
        "h5_content": "Dr. Meena Sharma",
        "h6_content": "Professor, Hon’ble Director Central Placement Cell, Panjab University",
        "link": "https://www.linkedin.com/in/drmeena/",
    },
]


// mentors page

//content of the members to render
export const MentorsTeam = [
    {
        "id": "0",
        "ImageSrc": "images/ranjit.jpg",
        "h5_content": "Flt. Lt. Ranjit Chauhan",
        "h6_content": "Pilot Instructor & War Veteran (1971) ",
        "referLinkClass" : "1",
        "linkClass" : "",
        "link": "",
    },
    {
        "id": "1",
        "ImageSrc": "images/Neelinderjeet.png",
        "h5_content": "Neelinderjeet Kaur Sandhu",
        "h6_content": "Director, SDVS Ambala",
        "referLinkClass" : "1",
        "linkClass" : "fa fa-link",
        "link": "http://www.sdvidyaambala.com/about/principal-message/",
    },
    {
        "id": "2",
        "ImageSrc": "images/Vasundhara.jpg",
        "h5_content": "Dr.Vasundhara Singh",
        "h6_content": "Professor, PEC Chandigarh",
        "link": "https://www.linkedin.com/in/vasundhara-singh-a7837859/",
    },
]

// our team page

//content of the team members to render
export const OurTeamData = [
    {
        "id": "0",
        "ImageSrc": "images/ashish.jpg",
        "h5_content": "Ashish Jain",
        "h6_content": "Chief Happiness Officer",
        "link": "https://www.linkedin.com/in/jainashish89",
    },
    {
        "id": "1",
        "ImageSrc": "images/sparsh.jpg",
        "h5_content": "Sparsh Jain",
        "h6_content": "Chief Technical Officer",
        "link": "https://www.linkedin.com/in/sparshjain",
    },
    {
        "id": "2",
        "ImageSrc": "images/garvit.jpeg",
        "h5_content": "Garvit Sharma",
        "h6_content": "Chief Marketing Officer",
        "link": "https://www.linkedin.com/in/garvit-sharma-662147154/",
    },
    {
        "id": "3",
        "ImageSrc": "images/vandana.jpg",
        "h5_content": "Vandana Bassi",
        "h6_content": "Head of Curriculum",
        "link": "https://www.linkedin.com/in/vandana-bassi-040205aa",
    },
    {
        "id": "4",
        "ImageSrc": "images/anubhav.jpg",
        "h5_content": "Anubhav Bhalla",
        "h6_content": "Head of Strategic Partnerships",
        "link": "https://www.linkedin.com/in/anubhav2499bhalla/",
    },
    {
        "id": "5",
        "ImageSrc": "images/siddhant.jpg",
        "h5_content": "Siddhant Sharma",
        "h6_content": "Visual Art Director",
        "link": "https://www.linkedin.com/in/siddhant-sharma-020101187/",
    },
    {
        "id": "6",
        "ImageSrc": "images/kritika.jpg",
        "h5_content": "Kritika Sharma",
        "h6_content": "Learning Manager",
        "link": "https://www.linkedin.com/in/kritika-sharma-5599611b0/",
    },
    {
        "id": "7",
        "ImageSrc": "images/soumya.jpg",
        "h5_content": "Soumya Sharma",
        "h6_content": "Learning Manager",
        "link": "https://www.linkedin.com/in/soumya-sharma-94b3901b4/",
    },
    {
        "id": "8",
        "ImageSrc": "images/kartikay.jpg" ,
        "h5_content": "Kartikay Tomar",
        "h6_content": "Social Media Strategist",
        "link": "https://www.linkedin.com/in/kartikay-tomar-9578b01b1/",
    },
    {
        "id": "9",
        "ImageSrc": "images/armaan.png",
        "h5_content": "Armaan Siddique",
        "h6_content": "Art Production Manager",
        "referLinkClass" : "1",
        "linkClass" : "fa fa-behance",
        "link": "https://www.behance.net/1armaansid84f6?tracking_source=search_users_recommended%7CArmaan%20siddiqui",
    },
    {
        "id": "10",
        "ImageSrc": "images/rahul.jpg",
        "h5_content": "Rahul Kathuria",
        "h6_content": "Tutoring Intern",
        "link": "https://www.linkedin.com/in/rahul-kathuria-434526198/",
    },
]

// values page

// values of the company
export const CompanyValues = [
    {
        "id": "0",
        "heading": "Trust",
        "text": "- we trust one another to do the right thing, always.",
    },
    {
        "id": "1",
        "heading": "Respect",
        "text": " - we respect one another's personal & professional choices.",
    },
    {
        "id": "2",
        "heading": "Aspiration",
        "text": " - we aspire to change the education, for better.",
    },
    {
        "id": "3",
        "heading": "Empowerment",
        "text": " - we aim to empower the future generation with the right skills.",
    },
]
/* ******************************************************************************* */




/* ***************************** single child ************************************ */


// student page

// content of the youtube videos to render in the carousel as per the carousel setup
export const aisha_videos_list = [
    //id --> id of the object, videoUrl --> url of the youtube video, 
    {
        "id": "0",
        videoUrl: "https://player.vimeo.com/video/473408581",
    },
    {
        "id": "1",
        videoUrl: "https://player.vimeo.com/video/473408478",
    },
    {
        "id": "2",
        videoUrl: "https://player.vimeo.com/video/473408383",
    },
    {
        "id": "3",
        videoUrl: "https://player.vimeo.com/video/473408127",
    },
    {
        "id": "4",
        videoUrl: "https://player.vimeo.com/video/473406074",
    },
    {
        "id": "5",
        videoUrl: "https://player.vimeo.com/video/473407218",
    },
    {
        "id": "6",
        videoUrl: "https://player.vimeo.com/video/473406022",
    },
    {
        "id": "7",
        videoUrl: "https://player.vimeo.com/video/473405837",
    },
    {
        "id": "8",
        videoUrl: "https://player.vimeo.com/video/473405696",
    },
    {
        "id": "9",
        videoUrl: "https://player.vimeo.com/video/473404489",
    },
    {
        "id": "10",
        videoUrl: "https://player.vimeo.com/video/473910902",
    },
    {
        "id": "11",
        videoUrl: "https://player.vimeo.com/video/473910844",
    },
]

export const aisha_values_list = [
    [
        {
            id: "0",
            VideoUrl: "https://player.vimeo.com/video/473406240",
            heading_text: "iTinker plays a big role in bringing the best out of me. ",
            paragraph_text1: " Hello everyone! My name is Aisha and I am studying in class 5th at YPS, Patiala." +
                "I like playing musical instruments, dancing, art and craft and I really enjoy learning" +
                " technology because it has the power to do many things, including changing the world we " +
                "live in and the way we live too.",
            paragraph_text2: "I came across iTinker at school during an awareness session, I was amazed " +
                "looking at how iTinker was contributing in bringing technology to school students' lives.",
            left_class: "col-md-5 wow slideInLeft",
            right_class: "col-md-6 wow slideInRight",
            first_to_render: "video",
        },
        {
            id: "1",
            VideoUrl: "https://player.vimeo.com/video/473451547",
            heading_text: " Learnt How to Control 16M Colors with an App ",
            paragraph_text1: "I have developed over thirty technical projects like Spy alarm system, " +
                "Bluetooth RGB LED, Car Alarm System, and an intelligence game too! I learnt how to use " +
                "Arduino and about other mobile applications like Remote XY and MIT apps through this platform.",
            paragraph_text2: "Things that I could only read and 'wow' at were now the things I was creating on " +
                "my own and that was the time when I really learnt how to put my thoughts into action! My most" +
                " favorite project is Bluetooth RGB LED Project because the fact that it's in my hands, my" +
                " command to change the colors of LED is just mind blowing!",
            left_class: "col-md-6 wow slideInLeft",
            right_class: "col-md-5 wow slideInRight",
            first_to_render: "written",
            addStyleVideo: "1",
        },
    ],
    [
        {
            id: "0",
            VideoUrl: "https://player.vimeo.com/video/473452139",
            heading_text: "iTinker has boosted my confidence in Technology ",
            paragraph_text1: "Mentors at iTinker are helpful and are always there for me. " +
                "It's because of them that I never really faced any kind of hindrances while doing the projects or coding.",
            paragraph_text2: "In my Chameleon project, I created a Machine Learning App and selected the objects of " +
                "different colors like pink, red and orange and I did coding. When I chose the colors from my selected" +
                " objects, the app recognized it and changed the color of Chameleon accordingly. Isn't it fascinating?!",
            left_class: "col-md-5 wow slideInLeft",
            right_class: "col-md-6 wow slideInRight",
            first_to_render: "video",
            addStyleVideo: "1",
        },
        {
            id: "1",
            displayImg: "1",
            ImageUrl: "images/Activity/aisha_risingstar.jpeg",
            heading_text: "I Love How we can Learn, Communicate & Participate at iTinker ",
            paragraph_text1: "I must say courses are brilliant and are pretty self - explanatory. " +
                "It's very easy to grasp the conceptual knowledge with the help of the quizzes, pictures and the games!" +
                " I love to do DIY projects and participate in various events. We get points whenever we complete" +
                " a course, make projects or play a quiz! On this platform, I can even share riddles, facts, jokes, etc." +
                " with my fellow learners.",
            paragraph_text2: "I have also won Rising Star of the Week and received Arduino starter kit" +
                " as a token of appreciation!",
            left_class: "col-md-6 wow slideInLeft",
            right_class: "col-md-5 wow slideInRight",
            first_to_render: "written",

        },
    ],
    [
        {
            id: "0",
            VideoUrl: "https://player.vimeo.com/video/473408245",
            heading_text: "Thank you iTinker for making 2020 a little better",
            paragraph_text1: "I always show my projects to my family and friends too! They really like and " +
                "appreciate it.Thanks to iTinker,I'm able to put this time at home to some productive use " +
                "and I enjoy it thoroughly!",
            paragraph_text2: "Thank you iTinker for helping me and giving me this great opportunity to" +
                " pursue my dreams and learn technology in a new and interesting way.I am really looking" +
                " forward to learn more and more from this platform!",
            left_class: "col-md-5 wow slideInLeft",
            right_class: "col-md-6 wow slideInRight",
            first_to_render: "video",
            addStyleVideo: "1",
        },
    ]

]


export const atharv_videos_list = [
    //id --> id of the object, videoUrl --> url of the youtube video, 
    {
        "id": "0",
        videoUrl: "https://player.vimeo.com/video/477469841",
    },
    {
        "id": "1",
        videoUrl: "https://player.vimeo.com/video/477469769",
    },
    {
        "id": "2",
        videoUrl: "https://player.vimeo.com/video/477469627",
    },
    {
        "id": "3",
        videoUrl: "https://player.vimeo.com/video/477469492",
    },
    {
        "id": "4",
        videoUrl: "https://player.vimeo.com/video/477062993",
    },
    {
        "id": "5",
        videoUrl: "https://player.vimeo.com/video/477062983",
    },
    {
        "id": "6",
        videoUrl: "https://player.vimeo.com/video/477062819",
    },
    {
        "id": "7",
        videoUrl: "https://player.vimeo.com/video/477062796",
    },
    {
        "id": "8",
        videoUrl: "https://player.vimeo.com/video/477062495",
    },
    {
        "id": "9",
        videoUrl: "https://player.vimeo.com/video/477062623",
    },
    {
        "id": "10",
        videoUrl: "https://player.vimeo.com/video/477469906",
    },
    {
        "id": "11",
        videoUrl: "https://player.vimeo.com/video/477062552",
    },
]

export const atharv_values_list = [
    [
        {
            id: "0",
            VideoUrl: "https://player.vimeo.com/video/477469841",
            heading_text: "iTinker brings me a step closer to living the dream ",
            paragraph_text1: '"Hello, my name is Atharv and I&apos;m an eighth grader at HPS, Panchkula.' +
                ' I thoroughly enjoy playing tabla and learning about technology. Technology connects me to ' +
                'the real world around me and gives my ideation a fly.I came across iTinker at school during' +
                ' an awareness session. I got myself registered and have been learning since that day. ' +
                'I started from the scratch, with rank 148th and as I kept completing the tasks and ' +
                'submitting them, I slowly slowly started stepping up the rank ladder nd reached rank 1 ' +
                'on the leadership board."',
            paragraph_text2: "",
            left_class: "col-md-5 wow slideInLeft",
            right_class: "col-md-6 wow slideInRight",
            first_to_render: "video",
        },
        {
            id: "1",
            VideoUrl: "https://player.vimeo.com/video/477469734",
            heading_text: " iTinker makes me Extraordinary! ",
            paragraph_text1: "Mentors at iTinker are very helpful especially Ashish sir who are always there" +
                " to guide me and help me out. They solve my problems right away and help me in understanding" +
                " the concepts I find difficult, especially coding. For example, team iTinker supported me to " +
                "make a project using ultrasonic sensor which helped my dad in car parking. If the bumper of the" +
                " car crosses 20cm distance from the nearest object, the buzzer starts beeping.",
            paragraph_text2: "",
            left_class: "col-md-6 wow slideInLeft",
            right_class: "col-md-5 wow slideInRight",
            first_to_render: "written",
        },
    ],
    [
        {
            id: "0",
            VideoUrl: "https://player.vimeo.com/video/477062623",
            heading_text: " Learning amazing projects! ",
            paragraph_text1: "I have developed over 30 projects including a spy alarm, Bluetooth RGB Led, " +
                "Emotion detector mobile app, clap lights, car alarm and many more. I always learn something" +
                " new with every new project. In distance alarm project, I worked with arduino, and OLED display," +
                " bluetooth etc. It&apos;s very fascinating and unthinkably everyone should have a hands-on " +
                "experience with them!",
            paragraph_text2: "",
            left_class: "col-md-5 wow slideInLeft",
            right_class: "col-md-6 wow slideInRight",
            first_to_render: "video",
        },
        {
            id: "1",
            VideoUrl: "https://player.vimeo.com/video/477469661",
            heading_text: "My parents, My biggest support! ",
            paragraph_text1: "My parents encourage me alot and help bringing the true potential out of me." +
                " My father always supports me financially and my mother appreciates me at every step of my project." +
                " I also share my projects with my engineer cousin. He always appreciates my hard work and gives " +
                "me amazing tips regarding them! It&apos;s because of my parents that I&apos; ve learnt so" +
                " much from such an amazing platform!",
            paragraph_text2: "",
            left_class: "col-md-6 wow slideInLeft",
            right_class: "col-md-5 wow slideInRight",
            first_to_render: "written",

        },
    ],
    [
        {
            id: "0",
            VideoUrl: "https://player.vimeo.com/video/477062993",
            heading_text: "iTinker gives my thinking wings!",
            paragraph_text1: "I want to develop a mobile application in which I&apos;d install a GPS for the protection" +
                " of senior citizens from the street dogs. Through this app, everyone would know about the location " +
                "of dogs and would safely step out of the house and maintain required distance from them and " +
                "help the old people who live in my neighbourhood !",
            paragraph_text2: "Lastly I want to thanks iTinker and my mentors for guiding and helping me at" +
                " every step! This is an amazing platform I&apos;ve been learning with them Thank you so much iTinker !",
            left_class: "col-md-5 wow slideInLeft",
            right_class: "col-md-6 wow slideInRight",
            first_to_render: "video",
        },
    ]

]

export const devina_values_list = [
    [
        {
            id: "0",
            VideoUrl: "https://player.vimeo.com/video/504056717",
            heading_text: "iTinker- A true learning companion!",
            paragraph_text1: "Hi everyone! I'm Devina Guleria of 8th grade from HPS, Panchkula. I came to" +
                " know about iTinker during this lockdown, when my teacher introduced this during an awareness " +
                "session held at our school. I was intrigued and I wanted to know what it is all about and so," +
                " I got myself registered and started working on my technical skills. I was amazed to see how" +
                " iTinker helps students like me to gel comfortably with technology.",
            paragraph_text2: "",
            left_class: "col-md-5 wow slideInLeft",
            right_class: "col-md-6 wow slideInRight",
            first_to_render: "video",
        },
        {
            id: "1",
            VideoUrl: "https://player.vimeo.com/video/504056577",
            heading_text: " Visualization is the new way of learning!",
            paragraph_text1: "With my school shut due to COVID-19, I found myself leaning more towards iTinker," +
                " where I got to learn more about the tech world. The videos are amazing and helped me in visualising" +
                " concepts.It focuses on improving and strengthening our basics. The video lessons, " +
                "with their simple language and visualisation, helped me learn clearly. " +
                "The videos are interactive which helped me get better with conceptual clarity. " +
                "Moreover the mentors are really amazing, who showed me the right approach to conceptual learning.",
            paragraph_text2: "",
            left_class: "col-md-6 wow slideInLeft",
            right_class: "col-md-5 wow slideInRight",
            first_to_render: "written",
            addStyleVideo: "1",
        },
    ],
    [
        {
            id: "0",
            VideoUrl: "https://player.vimeo.com/video/504056305",
            heading_text: " Passion never fails!!!",
            paragraph_text1: "So far I've made many DIY projects, to name a few - includes the clap lights," +
                " Snapchat and Instagram Filters, football game app, and many more. And out of these," +
                " the most challenging and my personal favourite was the clap lights project. " +
                "Nevertheless, I completed it with the help of the mentors. Teachers here are always ready " +
                "to lend a helping hand and are easily approachable. I got to learn a lot more about arduino," +
                " android development, AR, VR by doing these projects. Adding to it, we can even order the " +
                "materials required for the project from the iTinker shop. In a nutshell, iTinker is a personal" +
                " guidance kit for me!",
            paragraph_text2: "",
            left_class: "col-md-5 wow slideInLeft",
            right_class: "col-md-6 wow slideInRight",
            first_to_render: "video",
            addStyleVideo: "1",
        },
        {
            id: "1",
            VideoUrl: "https://player.vimeo.com/video/504056353",
            heading_text: "Involvement of parents! ",
            paragraph_text1: "My parents have been really supportive throughout my journey with iTinker." +
                " Wherever I was stuck with my project, my father would help me get through with it. " +
                "And when I showed them my first ever app, they appreciated me saying I'm at least utilising" +
                " my time productively rather than playing video games all day long. They were happy seeing " +
                "me gaining some practical knowledge in this lockdown.",
            paragraph_text2: "",
            left_class: "col-md-6 wow slideInLeft",
            right_class: "col-md-5 wow slideInRight",
            first_to_render: "written",

        },
    ],
    [
        {
            id: "0",
            VideoUrl: "https://player.vimeo.com/video/504056514",
            heading_text: "Falling in love with learning!",
            paragraph_text1: " iTinker helped me to get a headstart towards technology. It's lot of fun" +
                " learning from this platform. I would like to thank iTinker for giving me these amazing" +
                " courses and get smarter in the field of Artificial Intelligence and Algorithms. It keeps " +
                "me updated about the new technologies that are entering the world plus the technologies " +
                "used before. Thank you iTinker, for this amazing journey!",
            paragraph_text2: "",
            left_class: "col-md-5 wow slideInLeft",
            right_class: "col-md-6 wow slideInRight",
            first_to_render: "video",
            addStyleVideo: "1",
        },
    ]

]

export const sagar_values_list = [
    [
        {
            id: "0",
            VideoUrl: "https://player.vimeo.com/video/524235391",
            heading_text: "How iTinker Welcomed Me! ",
            paragraph_text1: "Hello everyone! I am Sagar, currently studying in 8th standard at HPS, Panchkula." +
                "My hobbies include reading books, swimming and many more.I got to know about iTinker in School" +
                " during a webinar, and I was really looking forward to starting with the sessions as soon as possible." +
                " Ashish Sir takes our online sessions and personally, i am very fascinated by it!",
            paragraph_text2: "",
            left_class: "col-md-5 wow slideInLeft",
            right_class: "col-md-6 wow slideInRight",
            first_to_render: "video",
            addStyleWritten: "1",
        },
        {
            id: "1",
            VideoUrl: "https://player.vimeo.com/video/524235577",
            heading_text: " Projects that I’ve learnt",
            paragraph_text1: "From Mobile App development, Arduino Basics, Clap Light and its advance level," +
                " Proximity Alarm,3D Designing in TinkerCad to Clap light which is something that surprises " +
                "me everytime and i feel It’s amazing!",
            paragraph_text2: "",
            left_class: "col-md-6 wow slideInLeft",
            right_class: "col-md-5 wow slideInRight",
            first_to_render: "written",
            addStyleWritten: "1",
        },
    ],
    [
        {
            id: "0",
            VideoUrl: "https://player.vimeo.com/video/524235162",
            heading_text: " My favorite Project",
            paragraph_text1: "One of my favourite projects is Smart Dustbin. It was just one day that it" +
                " occurred to me, with this pandemic going on, we shouldn't touch dustbins with bare hands" +
                " and all of a sudden this idea grew in my mind! It goes something like - Whenever we go near" +
                " the dustbin, it senses movement and opens up automatically. Ultrasonic sensors are installed " +
                "in it just like a Soap Dispensor. No touch!",
            paragraph_text2: "",
            left_class: "col-md-5 wow slideInLeft",
            right_class: "col-md-6 wow slideInRight",
            first_to_render: "video",
        },
        {
            id: "1",
            VideoUrl: "https://player.vimeo.com/video/524235324",
            heading_text: "My Parents - My Biggest Support ",
            paragraph_text1: " Both of my parents are teachers, and they help me a lot in establishing wire " +
                "connections and motivate me a lot. They were the reason why I gained my interest in technology " +
                "so much. They have been supportive throughout, and I owe whatever I have learnt to them. It is " +
                "mostly my father who helps me out having an expertise in Mechanical Engineering. He explains me " +
                "things whenever I get stuck. When he is not around, I contact my mentors and they guide me through!",
            paragraph_text2: "",
            left_class: "col-md-6 wow slideInLeft",
            right_class: "col-md-5 wow slideInRight",
            first_to_render: "written",

        },
    ],
    [
        {
            id: "0",
            VideoUrl: "https://player.vimeo.com/video/524235235" ,
            heading_text: "Suggested to my near and dear ones.",
            paragraph_text1: "I talk about my projects with all my teachers, friends, and cousins. They're "+
            "all very fascinated with my work and appreciate me a lot. I have made many new friends due to"+
             "my newly learnt knowledge! It brings me true bliss to share my knowledge and help others out!",
            paragraph_text2: "iTinker has played a major role in making my mind more innovative. A big thanks " +
                "to iTinker and all my mentors who guided me very well throughout my journey and continue to do" +
                " so. They are very kind and available every time I face any problems. I could not have asked" +
                " for better mentors! Thank you iTinker!",

            left_class: "col-md-5 wow slideInLeft",
            right_class: "col-md-6 wow slideInRight",
            first_to_render: "video",
        },
    ]

]

/* ************************************************************************** */


/* *********************************** career page *************************************** */

// career_information Page
export const career_information_list_fullTimeExpert = [
    {
        id: "0",
        mainDivClass: "row",
        image_class: "col-md-6 wow slideInLeft",
        image_src: "images/bring-change.png",
        written_class: "col-md-6 wow slideInRight",
        written_title: "Enable Grassroot Change",
        written: [
            {
                p1: "Help transform education in India by bringing in the much needed",
                p2: " right at the grassroot level",
                b1: " hands-on experience",
            },
            {
                p1: "",
                p2: "by using the best of available & future technologies in collaboration with curious & imaginative children",
                b1: " Create stuff that inspires",
            },
        ],
    },
    {
        id: "1",
        mainDivClass: "row pt-5",
        image_class: "col-md-6 wow slideInRight",
        image_src: "images/do-well.png",
        written_class: "col-md-6 wow slideInLeft",
        written_title: "Do well while doing good",
        written: [
            {
                p1: "iTinker offers highly ",
                p2: " to all its employees, at par with the market",
                b1: "competitive salaries",
            },
            {
                p1: "Secure yourself & your family's health also with the savings and ",
                p2: " for iTinker employees",
                b1: "healthcare benefits",
            },
        ],
    },
    {
        id: "2",
        mainDivClass: "row pt-5",
        image_class: "col-md-6 wow slideInLeft",
        image_src: "images/career.png",
        written_class: "col-md-6 wow slideInRight",
        written_title: "Build a Career",
        color_white: "1",
        written: [
            {
                p1: "",
                p2: " growth options- Help customers realize maximum benefits from their iTinker relationships & grow with them",
                b1: "Business",
            },
            {
                p1: "",
                p2: "growth options- Help schools make the most of their technical infrastructure and student potential. Become their go-to guru",
                b1: "Technical",
            },
            {
                p1: "",
                p2: " movements - Flexible options to move up in either domain or experience both by moving horizontally",
                b1: "Vertical and horizontal",
            },
        ],
    },
    {
        id: "3",
        mainDivClass: "row",
        image_class: "col-md-6 wow slideInRight",
        image_src: "images/life.png",
        written_class: "col-md-6 wow slideInLeft",
        written_title: "Build a Life!",
        color_white: "1",
        written: [
            {
                p1: "Move to ",
                p2: " within roles, with flexible relocation options & support",
                b1: "different locations",
            },
            {
                p1: "The ",
                p2: " as your life does, try different things based on life circumstances and preferences",
                b1: "job evolves",
            },
        ],
    },
]


export const career_information_list_freelancing = [
    {
        id: "0",
        mainDivClass: "row",
        image_class: "col-md-6 wow slideInLeft",
        image_src: "images/bring-change.png",
        written_class: "col-md-6 wow slideInRight",
        written_title: "Enable Grassroot Change",
        written: [
            {
                p1: "Help transform education in India by bringing in the much needed",
                p2: " right at the grassroot level",
                b1: " hands-on experience",
            },
            {
                p1: "",
                p2: "by using the best of available & future technologies in collaboration with curious & imaginative children",
                b1: " Create stuff that inspires",
            },
        ],
    },
    {
        id: "1",
        mainDivClass: "row pt-5",
        image_class: "col-md-6 wow slideInRight",
        image_src: "images/freelance2.png",
        written_class: "col-md-6 wow slideInLeft",
        written_title: "Work as You Like",
        written: [
            {
                p1: "All work is ",
                p2: " so work when you like and get paid for it immediately",
                b1: "project based",
            },
            {
                p1: "Get ",
                p2: " for your efforts plus quality based incentives",
                b1: "competitive renumerations",
            },
        ],
    },
    {
        id: "2",
        mainDivClass: "row pt-5",
        image_class: "col-md-6 wow slideInLeft",
        image_src: "images/freelance3.png",
        written_class: "col-md-6 wow slideInRight",
        written_title: "Convert to Full Time",
        color_white: "1",
        written: [
            {
                p1: "Options to ",
                p2: " based on requirements and relationship with iTinker",
                b1: "convert to full time work",
            },
            {
                p1: " section for details",
                p2: "Competitive salaries plus benefits for all employees. See ",
                b1: "full-time-experts",
            },
        ],
    },
    {
        id: "3",
        mainDivClass: "row",
        image_class: "col-md-6 wow slideInRight",
        image_src: "images/freelance4.png",
        written_class: "col-md-6 wow slideInLeft",
        written_title: "Work Anywhere, on Anything",
        color_white: "1",
        written: [
            {
                p1: "",
                p2: " - most of the freelancing work can be done online",
                b1: "Work moves with you",
            },
            {
                p1: "",
                p2: " to work on, ranging from content, design, IT, business and much more!",
                b1: "Choose your topic",
            },
        ],
    },
]

// open_positions page

export const openPositionValuesFreelancing = [
    {
        id: "0",
        h5_content: "Content Writer",
        h6_content: "",
        paragraph_text: "Write original,fun and interactive content on future technologies for school children." +
            "Choose from a variety of topics.",
        data_applied_value: "Content Writer",
    },
    {
        id: "1",
        h5_content: "Animation Expert",
        h6_content: "",
        paragraph_text: "Create story-based animations for children, in the short format (1-3 mins)." +
            " Choose from a variety of topics.",
        data_applied_value: "Animation Expert",
    },
    {
        id: "2",
        h5_content: "Technical Expert",
        h6_content: "",
        paragraph_text: 'Create technical content & "how-to" videos on IoT, Blockchain, 3D Printing,' +
            ' ML/AI etc.Deliever LIVE lectures on these topics.',
        data_applied_value: "Technical Expert",
    },
]

export const openPositionValuesFullTimeExpert = [
    {
        id: "0",
        h5_content: "Lab-in-charge",
        h6_content: "Chandigarh Area",
        paragraph_text: "Candidate should know his/her way around ML/AI, IoT, 3D Printing and other new" +
            " technologies. Should be able to build quick rapport with students and teachers alike.",
        data_applied_value: "Lab-in-charge",
    },
    {
        id: "1",
        h5_content: "Customer Success Manager",
        h6_content: "Chandigarh Area",
        paragraph_text: "Candidate should by extremely good in interpersonal skills, should have a sales" +
            " experience. Knowledge of technologies like ML/AI, 3D Printing, IoT etc. preferred.",
        data_applied_value: "Customer Success Manager",
    },
]


/* ************************************************************************** */


/* technovation */
const para = {
    p1 : 'Every year, Technovation Equips young women (ages\n' +
        '                    10-18) to become tech\n' +
        '                    entrepreneurs and leaders. With the support of volunteer mentors, girls work in\n' +
        '                    teams to code mobile\n' +
        '                    apps that address real-world problems.',
    p2 : 'iTinker is excited to bring you Technovation 2021 Challenge as its Ambassador',
}
export default para ;