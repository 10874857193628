import React from 'react'

// render the side images on the screen 
const SideImages = () => {
    return (
        <>
            <img src="images/arrow.png" alt="arrow" className="arrow" />
            <img src="images/bg3.png" alt="dog" className="bg3" />
        </>
    )
}

// handles rendering of video on the screen 
// classN --> class to add to the div ,
//item --> object of having all other required values(
//     addStyle --> 1 if we have to add the style in that row
//     displayImg --> 1 if the image is to display and not the video
//     VideoUrl --> url of the video of the student
// )
//mobile --> true if the screen size is of mobile otherwise false
const RenderVideo = ({ classN, item, mobile }) => {
    return (
        <div className={classN} style={item.addStyleVideo === "1" ? { marginTop: "30px" } : {}}>
            {
                item.displayImg === "1" ?
                    <img src={item.ImageUrl} alt="student" className="img-fluid"/>
                    :
                    <iframe
                        src={item.VideoUrl}
                        title={" iframe title"}
                        frameBorder="0"
                        width={mobile ? "350" : "450"}
                        height={mobile ? "300" : "350"}
                        allow="autoplay; fullscreen"
                        allowFullScreen={true}
                        >
                    </iframe>
            }
        </div>
    )
}

// render the written content on the screen 
// classN --> class to add to the div element, item --> object having all other values(
//     heading_text --> text to add to the h2 tag
//     paragraph_text1 --> content to add before the break tag
//     paragraph_text2 --> content to add after the creak tag
// )

const RenderWrittenContent = ({ classN, item }) => {
    return (
        <div className={classN} style={item.addStyleWritten === "1" ? { marginTop: "30px" } : {}}>
            <div className="py-4">
                <h2 id="h22">
                    {item.heading_text}
                </h2>
                <p>
                    {item.paragraph_text1}
                    <br />
                    {item.paragraph_text2}
                </p>
            </div>
        </div>
    )
}

// handles the overall rendering of elements on the screen as per the screen size
//item --> object having all the required values, mobile --> is the screen size is of mobile or not
const ShowRow = ({ item, mobile }) => {

    // true if the size of the screen is of mobile
    if (mobile)
        return (
            <div className="row">

                <RenderWrittenContent
                    item={item}
                    classN={item.right_class} />

                <RenderVideo
                    item={item}
                    mobile={mobile}
                    classN={item.left_class} />

            </div>
        )
    else
    // true if the screen size is greater than mobile size and the element to render on 
    //left side of the screen is video
        if (item.first_to_render === "video")
            return (
                <div className="row">

                    <RenderVideo
                        item={item}
                        classN={item.left_class} />

                    <RenderWrittenContent
                        item={item}
                        classN={item.right_class} />

                </div>
            )

            // true if the image size is of greater than mobile and the element to render on left 
            //side is written content 
        else
            return (
                <div className="row">

                    <RenderWrittenContent
                        item={item}
                        classN={item.left_class} />

                    <RenderVideo
                        item={item}
                        classN={item.right_class} />

                </div>
            )
}

function StudentWords(props) {
    return (
        <React.Fragment key={props.id}>
         {/*//handles the rendering of array inside the array*/}
            {props.values.map(value => (

                <div key={value.id} className="container" style={{ marginTop: "20px" }}>

                    {props.isMobile ? <></> : <SideImages />}

                    {/* handles the rendering of objects having inside the array */}
                    {value.map(item => (
                        <ShowRow key={item.id} item={item}
                            mobile={props.isMobile} />
                    ))}


                </div>
        
            ))}
        </React.Fragment>
    )
}

export default StudentWords
