import React, {useEffect} from 'react'

import SlideImage from './slide_image'
import OpenPositions from './open_positions'
import CareerInformation from './career_information'

function Career(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <SlideImage CareerType={props.CareerType}/>
            <InterestedIn CareerType={props.CareerType}/>
            <CareerInformation CareerType={props.CareerType} isMobile={props.isMobile}/>
            <OpenPositions CareerType={props.CareerType} isMobile={props.isMobile}/>
            <SubmitDetails/>
        </>
    )
}


function InterestedIn(props) {
    return (
        <section className="pb-5 pt-0" id="tabs">
            <div className="">
                <div className="col-xs-12">
                    <nav>
                        <div className="text-center wow zoomIn">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-10 md-offset-1">
                                        <div className="row">
                                            <div className="col-md-6">

                                                {/* here classname changes as per the selected option out of
                                                freelancing and full time expert, if the full time expert is currently
                                                rendering or selected it is displayed in the orange color otherwise not and
                                                 vide versa for freelancing career type option */}
                                                <a href="/fullTimeExpert"
                                                   className={
                                                       props.CareerType === "fullTimeExpert"
                                                           ?
                                                           "w-100 full-time d-inline-block mt-3 view-more rounded text-white text-decoration-none"
                                                           :
                                                           "unactive w-100 d-inline-block mt-3 view-more rounded text-white text-decoration-none"
                                                   }
                                                   style={{border: "2px solid #FF854D"}}>
                                                    I'm interested in full time work
                                                </a>

                                            </div>
                                            <div className="col-md-6">
                                                <a href="/freelancing"
                                                   className={
                                                       props.CareerType !== "fullTimeExpert"
                                                           ?
                                                           "w-100 full-time d-inline-block mt-3 view-more rounded text-white text-decoration-none"
                                                           :
                                                           "unactive w-100 d-inline-block mt-3 view-more rounded text-white text-decoration-none"
                                                   }>
                                                    I'm a freelancer
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </section>
    )
}

function SubmitDetails() {
    return (
        <section className="py-5 bg2"><br/>
            <div className="container wow zoomIn">

                <div className="row pt-5 pb-3 text-center">
                    <div className="col-md-12">
                        <h1 className="text-white">Can't Find a Role That Suits You?</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8 md-offset-2 text-center">

                        <p className="text-white">
                            Leave your details & resume and we'll get back to you shortly
                        </p>

                        <div className="text-center">
                            <a href="../contact"
                               className="d-inline-block mt-3 red-btn rounded text-white text-decoration-none">
                                Click Here
                            </a>
                        </div>

                    </div>
                </div>

            </div>
        </section>
    )
}


export default Career
