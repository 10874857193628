import React, {useEffect} from 'react'

// various components to render when features page is requested
import {FeaturesList, PlatformFeaturesList} from "../../utils/constants";

function Features() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {/* the slide image of the feature page*/}
            <SlideImage/>

            {/* the main features of the company*/}
            <PlatformFeatures/>

            {/* the list of all the features */}
            <ListOfAllFeatures/>

            <FeelingExcited/>
        </>
    )
}

function SlideImage() {
    return (
        <div className="page-banner py-5 text-center">
            <div className="container">
                <div className="row wow zoomIn">

                    <div className="col-md-8 md-offset-1">
                        <h2>Best-in-class learning methodologies & technologies, to make our children
                            <span>Future Ready</span>
                        </h2>
                    </div>

                    <div className="col-md-4">
                        <img src="images/business-challenge.png"
                             alt="business-challenge"
                             className="img-fluid margin-top"/>
                    </div>

                </div>
            </div>
        </div>
    )
}

function PlatformFeatures() {
    return (
        <>
            <section className="pt-3 pb-5 features-pg">

                <img src="images/bg-5.png" alt="side_image" className="bg-5"/>
                <img src="images/bg6.png" alt="side_image" className="bg6"/>

                <div className="container">

                    {/* title of the page */}
                    <div className="row wow zoomIn">
                        <div className="col-md-12 text-center">
                            <h1>Platform Features</h1>
                        </div>
                    </div>

                    {/* content of the component */}
                    <div className="row pt-5 text-center ">
                        <div className="col-md-10 md-offset-1">

                            {/* renders the array(row features) of the array */}
                            {PlatformFeaturesList.map(features => (
                                <div className="d-md-inline-flex wow zoomIn">

                                    {/* renders each feature of the array */}
                                    {features.map(feature => (
                                        <div className={feature.className}>

                                            <img src={feature.image} alt="Feature" className="py-3"/>

                                            <h6 className="text-white pt-4 pb-4">{feature.title}</h6>

                                            <ul className="text-left pl-3 text-white">
                                                {feature.text.map(line => (<li>{line}</li>))}
                                            </ul>

                                        </div>
                                    ))}

                                </div>
                            ))}

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

function ListOfAllFeatures() {
    return (
        <>
            <section className="py-5 all-features">
                <div className="container">

                    {/* title of the page */}
                    <div className="row text-center wow zoomIn">
                        <div className="col-md-12">
                            <h1>List of all features</h1>
                        </div>
                    </div>

                    {/* content of the page , it renders the list of the features */}
                    <div className="row pt-5 wow zoomIn">
                        <div className="col-md-10 md-offset-1">
                            <div className="row">

                                {/* renders each column */}
                                {FeaturesList.map(feature => (
                                    <div className="col-md-4">
                                        <ul className="list-unstyled">

                                            {/* renders each element of the column */}
                                            {feature.map(f => (
                                                <li>{f}</li>
                                            ))}

                                        </ul>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 text-center">
                    <h3>... and so much more!</h3>
                </div>

            </section>
        </>
    )
}

function FeelingExcited() {
    return (
        <>
            <section className="py-5 bg2"><br/>
                <div className="container">

                    {/* title of the page */}
                    <div className="row pt-5 pb-3 text-center wow zoomIn">
                        <div className="col-md-12">
                            <h1 className="text-white">Feeling Excited?</h1>
                        </div>
                    </div>

                    <div className="row wow zoomIn">
                        <div className="col-md-8 md-offset-2 text-center">

                            <p className="text-white">
                                Get on with us on a journey to explore future technologies and make
                                your dreams come true.
                            </p>

                            <div className="text-center">
                                <a href="/contactUs"
                                   className="d-inline-block mt-3 red-btn rounded text-white text-decoration-none"
                                   style={{cursor: "pointer"}}>Try a free Demo Now
                                </a>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Features
