import React from 'react'
import {ElementsToRender} from '../../utils/constants'

//render the side images on the screen
const SideImages = () => {
    return (
        <>
            <img src="images/bg-5.png" alt="Imagee" className="bg-5" />
            <img src="images/bg6.png" alt="Imagee" className="bg6" />
        </>
    )
}

// render the image on the screen 
// id --> id of the element to render, mobile --> is the screen size is of mobile or not
const ImageRender = ({ id, mobile }) => {
    return (
        <img src={ElementsToRender[id].image.ImageSource}
            alt="schools"
            className="img-fluid"
            style={mobile ? { marginTop: "-70px" } : { marginTop: "-40px" }} />
    )
}

//render the written content on the screen 
// id --> id of the object whose written content is to render
const WrittenContentRender = ({ id }) => {
    return (
        <>
            {/* renders the written content */}
            {ElementsToRender[id].div.data.map(feature => (
                <p key={feature.id} className={ElementsToRender[id].headingClass}>
                    {ElementsToRender[id].div.BeforeContent === '1' ? feature.before : ""}
                    <b>{feature.title}</b>
                    {ElementsToRender[id].div.BeforeContent ? feature.after : feature.text}
                </p>
            ))}

        </>
    )
}

// render the title of the element on the screen
const TitleRender = ({ id }) => {
    return (
        <>
            {/* title of the for students part */}
            <h1 className={ElementsToRender[id].headingClass}>
                iTinker For {ElementsToRender[id].title}
            </h1>
        </>
    )
}

// handles the rendering of element per row
// id --> id of the object whose content is to handle, 
//mobile --> is the screen size is of mobile or not
const ShowRow = ({ id, mobile }) => {

    // true if the screen size is of mobile
    if (mobile) {
        return (
            <div className="row">
                <div className={ElementsToRender[id].div.class}>
                    <div className={ElementsToRender[id].class_mob}>
                        <TitleRender
                            id={id} />
                        <ImageRender
                            id={id}
                            mobile={mobile} />
                        <WrittenContentRender
                            id={id} />
                    </div>
                </div>
            </div>
        )
    }
    else {

        // true if the screen size is not of mobile and the first element to render is set to image
        if (ElementsToRender[id].firstToRender === "image") {
            return (
                <div className={ElementsToRender[id].class}>

                    {/* image to render */}
                    <div className={ElementsToRender[id].image.class}>
                        <ImageRender
                            id={id} />
                    </div>

                    <div className={ElementsToRender[id].div.class}>
                        <div className="py-5">

                            {/* render title of the element */}
                            <TitleRender
                                id={id} />

                            {/* render written content of the element */}
                            <WrittenContentRender
                                id={id} />

                        </div>
                    </div>

                </div>
            )
        }
        // render if the screen size is not of mobile and first element to render is written content
        else {
            return (
                <div className={ElementsToRender[id].class}>

                    <div className={ElementsToRender[id].div.class}>
                        <div className="py-5">
                            <TitleRender
                                id={id} />
                            <WrittenContentRender
                                id={id} />

                        </div>
                    </div>

                    {/* image to render at for students part */}
                    <div className={ElementsToRender[id].image.class}>
                        <ImageRender
                            id={id} />
                    </div>

                </div>
            )
        }
    }

}

function ItinkerFor(props) {

    return (
        <React.Fragment>
            <section className="py-5 features" style={{ backgroundSize: "100% 56%" }}>

                {/* render the side images only if the screen size is greater than mobile size */}
                {props.isMobile ? "" : <SideImages /> }

                <div className="container">

                    {/* render for all the object in the array 'ElementsToRender' */}
                    {ElementsToRender.map(element => (
                        <ShowRow
                            id={element.id}
                            mobile={props.isMobile}
                        />
                    ))}


                </div>

            </section>
        </React.Fragment>
    )
}

export default ItinkerFor
