import React, {useEffect, useState} from 'react'

//files required to load for the carousel look 
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import "./main_style.css"

const SlideImages = () =>{
    return(
        <>
            <img src="images/arrow.png" alt="Arrow_Image" className="arrow" />
            <img src="images/bg3.png" alt="Dog_Image" className="bg3" />
        </>
    )
}

const RenderTitle = (props) =>{
    return(
        <div className="row  text-center wow zoomIn">
            <div className="col-md-12">
                <h1>{props.title}</h1>
            </div>
        </div>
    )
}
// main function of the component
// props --> title (title of the list to render as carousel), 
// data (list or array of the members to render in carousel),
// id --> id of the object
function CarouselLook(props) {

    // variable isMobile checks is the screen size is less than 520 or not
    const [isMobile, setIsMobile] = useState(window.innerWidth < 520);

    // runs everytime screen size changes and set the value accordingly
    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < 1200;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);

    return (
        <section id="team">
            <div className="container py-5">

                {/* side images of the component */}
                <SlideImages/>

                {/* title of the carousel list */}
                <RenderTitle title = {props.isMobile} />

                <div className="col-md-11 md-offset-1">
                    <div className=" owl-theme">

                        {/* owl carousel to render the list */}
                        {/*displays the open positions in carousel form*/}
                        {/* items = if the screen size is of mobile then only 1 item is rendered
                        otherwise 3 items will render on the screen*/}
                        <OwlCarousel className='owl-theme' items={isMobile ? 1 : 3} nav>

                            {/* main content of the page */}
                            {props.data.map(Member => (

                                // id --> unique id of the object
                                // memberSizeStyle --> styling on size of the member
                                <div key={Member.id} className="item column " >
                                    <div className="p-3 shadow bg-white text-center"
                                        style={props.memberSizeStyle}>

                                        {/* ImageSrc --> image of the member to render */}
                                        <img src={Member.ImageSrc}
                                            alt="image_of_member"
                                            className="img-fluid" />

                                        {/* memberInfoStyle --> styling for the info
                                        rendering of member e.g. name, country, description etc. */}
                                        <div className="py-3" style={props.memberInfoStyle}>

                                            {/* h5_content --> information to render in h5 tag (name , country etc) */}
                                            <h5>{Member.h5_content}</h5>

                                            {/* fontSizeStyle --> styling of the font, 
                                            h6_content --> content to render iin h6 tag (description, etc) */}
                                            <h6 style={props.fontSizeStyle}>{Member.h6_content}</h6>

                                        </div>

                                        {/* rendering of link to further know about the member majorly linkedIn */}
                                        <ul className="list-unstyled list-inline">
                                            <li className="list-inline-item">

                                                <a href={Member.link}
                                                    target="_blank" rel="noreferrer"
                                                    className="text-dark">

                                                    <i className={Member.referLinkClass === "1" ? Member.linkClass : "fa fa-linkedin"}
                                                        aria-hidden="true">
                                                    </i>

                                                </a>

                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            ))}

                        </OwlCarousel>

                    </div>
                </div>

            </div>
        </section>
    )
}

export default CarouselLook
