import React from 'react'
import {schools} from '../../utils/constants'

function PartnerSchool(props) {

    return (
        <div className={props.isMobile ? "mob-sec" : "web-sec"}>
            <section className="py-5">
                <div className="container">
                    <div className="row  text-center wow zoomIn">

                        {/* sets the className as per the value of the isMobile */}
                        <div className={`${props.isMobile ? "col-md-12" : "col-md-8"}`}>

                            <h1>Our Partner Schools</h1>

                            <br />

                            <div className="row">

                                {/* runs for each element of the array schools */}
                                {schools.map(school => (

                                    <a key={school.id} href={school.link}
                                        target="_blank" rel="noreferrer"
                                        title={school.title}>

                                        <img src={school.SourceImg}
                                            alt="School"
                                            height={props.isMobile ? "100" : "200"} />

                                    </a>

                                ))}

                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default PartnerSchool
