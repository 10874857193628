import React, {useEffect} from 'react'
import para from '../../utils/constants'
import OurImpact from "./our_impact";

function Technovation(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="item">

            {/*render the slide image on the screen */}
            <SlideImage isMobile={props.isMobile}/>

            {/* renders the rows having information for our impacts */}
            <OurImpact isMobile={props.isMobile}/>

            {/* renders the section having information about technovation chapters */}
            <TechnovationChapters isMobile={props.isMobile}/>

            {/* renders the section titled want to get started on the screen */}
            <WantToGetStarted isMobile={props.isMobile}/>
        </div>
    )
}

// handles the rendering of 'wants to get started' part of the page
function WantToGetStarted(props) {
    return (
        <section className={props.isMobile ? "py-5 pink-bg-mob mob-sec" : "py-5 pink-bg web-sec"}>
            <br/>
            <div className="container">
                <div className="row pt-5 pb-3 text-center wow zoomIn">
                    <div className="col-md-12">
                        <h1 id="h11" className="text-white">Want to Get Started?</h1>
                    </div>
                </div>
                <div className="row wow zoomIn">
                    <div className="col-md-8 md-offset-2 text-center">
                        <h2 id="h22" className="text-white">Get in touch with Technovation for details</h2><br/>
                        <a href="https://rb.gy/piiebw" target="_blank" className="fl-button"
                           rel="noreferrer"
                           role="button">
                            <span id="b1" className="fl-button-text btn btn-success">Sign Up Today!</span>
                        </a>
                    </div>
                </div>
            </div>
        </section>

    )
}

// handles the rendering of written content of technovation chapters part of the page
function TechnovationChapterWrittenContent(props) {
    return (
        <>
            <h1 id="h11" style={props.isMobile ? {fontSize: "38px"} : {}}>Technovation Chapters</h1>
            <p id="p1"> Since 2010, more than 25,000 young people from 100+ countries have participated
                in
                Technovation Girls.<br/>
                With the help of volunteer mentors, they've produced mobile app startups that have
                helped
                address problems in local and global communities the world over.</p>
            <p id="p1">And while each year more and more teams take up the challenge, we know that there
                are
                always more problems to solve … and that there are people from every part of the world
                ready
                to
                solve them.
            </p>
        </>
    )
}

// handles the rendering of image of technovation chapters part
function TechnovationChaptersImage(props) {
    return (

        // it sets the width, height and style based on the screen size
        <iframe width={props.isMobile ? "640" : "560"}
                height={props.isMobile ? "360" : "315"}
                title={"technovation chapters video"}
                src="https://abcnews.go.com/video/embed?id=58425181"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={props.isMobile ? {border: "none"} : {marginTop: "100px", marginLeft: "-100px"}}/>
    )
}

// handles the rendering of technovation chapters
function TechnovationChapters(props) {

    // true if the screen size is of mobile otherwise else part is rendered
    if (props.isMobile)
        return (
            <section className="py-5 mob-sec text-center">
                <div className="container">
                    <div className="row">
                        <TechnovationChaptersImage
                            isMobile={props.isMobile}/>
                    </div>
                    <div className="row pt-5">
                        <div className="col-md-12 wow slideInLeft text-center">
                            <TechnovationChapterWrittenContent
                                isMobile={props.isMobile}/>
                        </div>
                    </div>
                </div>
            </section>
        )
    else
        return (
            <section className="py-5 web-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 wow slideInLeft">
                            <TechnovationChaptersImage
                                isMobile={props.isMobile}/>
                        </div>
                        <div className="col-md-6 wow slideInRight">
                            <div className="py-5">
                                <TechnovationChapterWrittenContent
                                    isMobile={props.isMobile}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        )
}

// handles the rendering of sign up link on the screen
function SignUp() {
    return (
        <a href="https://rb.gy/piiebw" target="_blank" className="fl-button"
           rel="noreferrer"
           role="button">
            <span id="p1" className="fl-button-text btn btn-primary">Sign Up!</span></a>
    )
}

// handles the rendering of slide image on the screen
function SlideImage(props) {
    return (
        <div id="container2" className={props.isMobile ? "mob-sec" : "web-sec"}>

            <img
                src={props.isMobile ? "images/technovation/bg_banner_mob.jpg" : "images/technovation/bg_banner.jpg"}
                style={props.isMobile ? {width: "100%"} : {width: "100%", height: "670px"}}
                alt="technovation"/>

            <div id={props.isMobile ? "centeredMob" : "centered2"}>

                <img src="images/technovation/logo.jpg" className={props.isMobile ? "" : "text-center"}
                     style={props.isMobile ? {width: "60%", marginBottom: "20px"} : {width: "40%"}}
                     alt="Technovation logo"/>

                {props.isMobile ? <></> : <><br/><br/></>}

                <h1>Girls for a change</h1>

                {/* renders single br tag in case of mobile view and 2 br tags in case of full screen view */}
                <p id="p1" className="text-white">
                    {/*renders the object para having two items :-- p1, p2 */}
                    {para.p1}
                    {props.isMobile ? <br/> : <><br/><br/></>}
                    {para.p2}
                    {props.isMobile ? <br/> : <><br/><br/></>}
                    <SignUp/>
                    {props.isMobile ? <br/> : <><br/><br/></>}
                </p>
                <h2>Partners</h2>
                <br/>

                {/*renders the images of partners , both images in case of mobile view and
                only first in case of full screen view */}
                <img src="images/itinker.jpg" className="text-center"
                     style={props.isMobile ? {width: "15%"} : {width: "12%"}} alt="iTinker logo"/>

                {props.isMobile ? <img src="images/technovation/navyug.jpg" className="text-center"
                                       style={props.isMobile ? {width: "15%"} : {width: "12%"}} alt="Navyug logo"/>
                    : <></>}
            </div>
        </div>

    )
}


export default Technovation;