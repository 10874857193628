import React, {useEffect} from 'react'

// components to render on the request of about page
import Vision from './vision'
import Mission from './mission'
import Values from './values'
import CarouselLook from "../carousel";
import {AdvisoryBoardTeam, MentorsTeam, OurTeamData} from "../../utils/constants";

// main function of the about component
function About(props) {

    // scrolls up the page to the top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <React.Fragment>

            <ScreenImage/> {/* renders the about screen image */}

            {/* section having vision, mission and values of the company */}
            <section
                className={props.isMobile ? "py-5 features section_class mob-sec" : "py-5 features section_class web-sec"}>
                <div className="container">

                    <Vision isMobile={props.isMobile}/>
                    <br/><br/>
                    <Mission isMobile={props.isMobile}/>
                    <br/><br/><br/>
                    <Values isMobile={props.isMobile}/>

                </div>
            </section>

            {/* component having code to show carousel of the advisory board members of the company */}
            <AdvisoryBoard isMobile={props.isMobile} />

            {/* component having carousel of the mentors of the company */}
            <Mentors isMobile={props.isMobile} />

            {/* component having members of the team of the company */}
            <OurTeam isMobile={props.isMobile} />

            {/* support us part of the about page */}
            <SupportUs/>

        </React.Fragment>
    )
}

function ScreenImage() {
    return (
        <React.Fragment>
            <div className="page-banner py-5 text-center">
                <div className="container">
                    <div className="row">

                        <div className="col-md-8 md-offset-1">
                            <h2>Experienced Team, Best-In-Class Curriculum, Proven Learning Methodologies, All For
                                <span> Non-Profit Purpose!</span>
                            </h2>
                        </div>

                        <div className="col-md-4"><br /><br /><br />
                            <img src="images/aba.png" alt="about us screen" className="img-fluid margin-top" />
                        </div>

                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}

function AdvisoryBoard(props) {
    return(

        // renders the carousel look having all the members of the array
        <CarouselLook
            data={AdvisoryBoardTeam}
            title="Advisory Board"
            memberInfoStyle={{height : "100px"}}
            fontSizeStyle={{fontSize : "small"}}
            memberSizeStyle={{ height: "360px", width: "220px"}}
        />
    )
}

function Mentors(props) {
    return(
        <CarouselLook
            data={MentorsTeam}
            title="Mentors"
            memberInfoStyle={{height : "120px"}}
            memberSizeStyle={{ height: "390px", width: "230px"}}
        />
    )
}

function OurTeam(props) {
    return(
        <CarouselLook
            data={OurTeamData}
            title="Our Team"
            memberInfoStyle={{height : "100px"}}
            memberSizeStyle={{ height: "390px", width: "230px"}}
        />
    )
}

const animationStyle = { visibility: "visible", animationName: "zoomIn" }

function SupportUs() {
    return (
        <section className="py-5 bg2"><br />
            <div className="container">

                <div className="row pt-5 pb-3 text-center wow zoomIn animated"
                     style={animationStyle} >
                    <div className="col-md-12">

                        <h1 className="text-white">Like what you see?</h1>

                    </div>
                </div>

                <div className="row wow zoomIn animated" style={animationStyle} >
                    <div className="col-md-8 md-offset-2 text-center">

                        <p className="text-white">
                            Help us realize this vision of a better, more inclusive world
                        </p>

                        <div className="text-center">
                            <a href="/support_us"
                               className="d-inline-block mt-3 red-btn rounded text-white text-decoration-none">
                                Support Us
                            </a>
                        </div>

                    </div>
                </div>

            </div>
        </section>
    )
}


export default About
