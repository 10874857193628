import React, {useEffect} from 'react'

import '../main_style.css'

import QueryForm from './query_form'

function ContactUs() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {/* the title screen rendering */}
            <ScreenSlide/>

            {/* the query or contact form */}
            <QueryForm/>

            {/* get in touch details of the company */}
            <GetInTouch/>
        </>
    )
}

function ScreenSlide() {
    return (
        <div className="page-banner py-5 text-center">
            <div className="container">
                <div className="row">

                    {/* the written content to render on the title part of the contact us page */}
                    <div className="col-md-8 md-offset-1">
                        <h2><span>Contact us</span></h2>
                        <p>
                            <b>
                                For queries, please fill the form below.
                                <br/>
                                Our team will get back within next 12 hrs.
                            </b>
                        </p>
                    </div>

                    {/* the image to render on the title part */}
                    <div className="col-md-4">
                        <img src="images/contact-b.png" alt="contact" className="img-fluid margin-top"/>
                    </div>

                </div>
            </div>
        </div>
    )
}

function GetInTouch() {
    return (
        <section className="" style={{marginBottom: "-30px"}}><br/>
            <div className=" pt-5 ">
                <div className="d-md-inline-flex w-100">

                    {/* renders the location using map on the screen */}
                    <div className="w-50">
                        <div style={{width: "100%", position: "relative"}}>
                            <iframe
                                width="100%"
                                title={"location"}
                                height="440"
                                src="https://maps.google.com/maps?width=100%&amp;height=440&amp;hl=en&amp;q=chandigarh+(Title)&amp;ie=UTF8&amp;t=&amp;z=10&amp;iwloc=B&amp;output=embed"
                                scrolling="no"
                                marginHeight="0"
                                marginWidth="0"/>

                            <div className="mapStyle">
                            </div>
                        </div>
                        <br/>
                    </div>

                    {/* renders the information to be in touch with the company */}
                    <div className="w-50 p-5 address">
                        <div className="p-5">
                            <h4 className="text-white">Get in touch</h4>
                            <ul className="list-unstyled text-white">
                                <li><i className="fa fa-envelope-o"
                                       aria-hidden="true"/>&nbsp;&nbsp;&nbsp;support@3dwe.in
                                </li>
                                <li><i className="fa fa-phone" aria-hidden="true"/>&nbsp;&nbsp;&nbsp;+91 83607 60917
                                </li>
                                <li><i className="fa fa-map-marker" aria-hidden="true"/>&nbsp;&nbsp;&nbsp;Chandigarh
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}


export default ContactUs
