import React from 'react'


function Modal() {
    return (


        <div className="modal fade pr-0" id="Apply" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Enter your Details</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form id="applyForm" action="#" className="" method="POST" noValidate>
                            <div className="form-group">

                                <label htmlFor="name">Name *</label>
                                <input type="text" className="form-control" id="name"
                                       placeholder="Enter Your Name" name="name"
                                       required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email *</label>
                                <input type="email" className="form-control" id="email"
                                       placeholder="Enter Your Email" name="email"
                                       required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone">Phone *</label>
                                <input type="text" className="form-control" id="contact" data-parsley-type="number"
                                       data-parsley-maxlength="10" data-parsley-minlength="10"
                                       placeholder="Enter Phone Number" name="contact" required/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="msg">Message *</label>
                                <textarea type='text' className="form-control" rows="4" id="message"
                                          placeholder="Your Message"
                                          name="message" required/>
                            </div>

                            <div className="text-center">
                                <input type="hidden" id="applied" name="applied" value=""/>
                                <input type="submit" name="submit" className="btn red-btn text-white" value="Submit"/>

                                <div className="text-center" style={{marginTop: "20px"}}>
                                    <div id="success" style={{display: "none", color: "green"}}>Thank you for your

                                        interest. We will be in touch with you soon.
                                    </div>
                                    <div id="error">Problem submitting your request, Please try
                                        again later.
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default Modal
