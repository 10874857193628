import React, {useEffect} from 'react'

import ITinkerFor from './itinker_for'
import PartnerSchool from '../home/partner_school'

function Schools(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <React.Fragment>
            <SlideImage/>
            <ITinkerFor isMobile={props.isMobile}/>
            <PartnerSchool/>
            <TryUs/>
        </React.Fragment>
    )
}

function SlideImage() {
    return (
        <React.Fragment>

            <div className="page-banner py-5 text-center">
                <div className="container">
                    <div className="row wow zoomIn">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12 ">
                                    <h2>Come, Join The Indian School Skilling Revolution</h2>
                                </div>
                            </div>
                            <div className="web-sec"><br/><br/><br/><br/></div>
                        </div>
                        <div className="col-md-5">
                            <img src="images/schools.png"
                                 alt="schools"
                                 className="img-fluid margin-top"/>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

function TryUs() {
    return (
        <React.Fragment>
            <section className="py-5 bg2"><br/>
                <div className="container">

                    <div className="row pt-5 pb-3 text-center wow zoomIn">
                        <div className="col-md-12">
                            <h1 className="text-white">Want to Get Started?</h1>
                        </div>
                    </div>

                    <div className="row wow zoomIn">
                        <div className="col-md-8 md-offset-2 text-center">

                            <p className="text-white">
                                Get in touch with us for details on our subscription models
                            </p>

                            <div className="text-center">
                                <a href="/contact"
                                   className="d-inline-block mt-3 red-btn rounded text-white text-decoration-none"
                                   style={{cursor: "pointer"}}>Try Us
                                </a>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
        </React.Fragment>
    )
}


export default Schools
