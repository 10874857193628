import React, {useEffect, useState} from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import './screens/home/homepage.css'

import Header from './screens/header'
import HomePage from './screens/home/homepage.js'
import Footer from './screens/footer/footer'
import Modal from './screens/modal'
import Features from './screens/features/features';
import Schools from './screens/schools/schools';
import About from './screens/about/about';
import TinkerWorld from './screens/tinkerworld/tinker_world';
import StudentPage from './screens/single_child/student_page';
import ContactUs from './screens/contact/contact_us';
import Career from './screens/career/career';
import Technovation from "./screens/technovation/technovation";
import Terms from "./screens/footer/terms";
import Privacy from "./screens/footer/privacy";

// the main function which handles all the renderings on the screen 
function App() {

    // variable isMobile checks is the screen size is less than 520 or not
    const [isMobile, setIsMobile] = useState(window.innerWidth < 520);

    // runs everytime screen size changes and set the value accordingly
    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < 1200;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);

    return (
        <BrowserRouter>{/* used for doing client side routing with URL segments*/}
            <Header isMobile={isMobile} /> {/* the navbar of the website*/}
            <div className="slide-container" >
            <Switch> {/* handles which screen to render as per path */}
                <Route exact path="/">
                    <HomePage isMobile={isMobile} /> {/* renders home page of the website */}
                </Route>
                <Route path="/features">
                    <Features/> {/* renders features page of the website */}
                </Route>
                <Route path="/schools">
                    <Schools isMobile={isMobile} /> {/* renders schools page of the website */}
                </Route>
                <Route path="/about">
                    <About/> {/* renders about page of the website */}
                </Route>
                <Route path="/technovation">
                    <Technovation isMobile={isMobile} /> {/* renders technovation page of the website */}
                </Route>
                <Route path="/tinkerWorld">
                    <TinkerWorld isMobile={isMobile} /> {/* renders tinker world page of the website */}
                </Route>
                <Route path="/freelancing">
                    <Career
                        CareerType="freeLancing"
                        isMobile={isMobile}
                    /> {/* renders career page of the website */}
                </Route>
                <Route path="/fullTimeExpert">
                    <Career
                        CareerType="fullTimeExpert"
                        isMobile={isMobile}
                    /> {/* renders career page of the website */}
                </Route>
                <Route path="/contactUs">
                    <ContactUs/> {/* renders contact us page of the website */}
                </Route>
                <Route path="/terms">
                    <Terms/> {/* renders terms and conditions page of the website */}
                </Route>
                <Route path="/privacy">
                    <Privacy/> {/* renders privacy page of the website */}
                </Route>
                <Route path="/aboutAisha">
                    <StudentPage student="Aisha" isMobile={isMobile}/> {/* renders student's page */}
                </Route>
                <Route path="/aboutAtharv">
                    <StudentPage student="Atharv" isMobile={isMobile}/> {/* renders student's page */}
                </Route>
                <Route path="/aboutDevina">
                    <StudentPage student="Devina" isMobile={isMobile}/> {/* renders student's page */}
                </Route>
                <Route path="/aboutSagar">
                    <StudentPage student="Sagar" isMobile={isMobile}/> {/* renders student's page */}
                </Route>
            </Switch>
            </div>
            <Footer/> {/* the footer of the website*/}
            <Modal/>
        </BrowserRouter>
    );
}

export default App;
