import React, {useEffect} from 'react'
import './homepage.css'

// components required to load the home page of the website
import SlideShowLook from "../slides";
import {home_team, platform_features, represent, slides} from "../../utils/constants";
import CarouselLook from "../carousel";
import ForSchool from "./for_school";
import PartnerSchool from "./partner_school";

function HomePage(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {/* the slide show part of the website */}
            <SlideShowLook slides={slides}/>
            <FeaturesAndSchool isMobile={props.isMobile}/>
            <Team isMobile={props.isMobile}/>
            <IRepresent/>
        </>
    )
}

function FeaturesAndSchool(props) {
    return (
        <section className="py-5 features">

            <img src="images/bg-5.png" alt="image1" className="bg-5"/>
            <img src="images/bg6.png" alt="Image2" className="bg6"/>


            <div className="container">

                {/* **************** Platform Features ***************** */}
                <PlatformFeatures/>

                {/* ************** Partner schools ****************** */}
                <PartnerSchool isMobile={props.isMobile}/>

                {/* ************** iTinker for schools *************** */}
                <ForSchool isMobile={props.isMobile}/>

            </div>

        </section>
    )
}

function IRepresent() {
    return (
        <section className="py-5 bg2">
            <div className="container">

                {/* title of the page */}
                <div className="row py-5 text-center wow zoomIn">
                    <div className="col-md-12">
                        <h1 className="text-white">I represent</h1>
                    </div>
                </div>

                {/* content of the page */}
                <div className="row wow zoomIn">
                    <div className="col-md-10 md-offset-1">
                        <div className="d-md-inline-flex w-100">

                            {represent.map(member => (
                                <div key={member.id} className="ima bg-white p-4 text-center">

                                    <img src={member.ImageSrc} alt={member.a_text}/>

                                    <div className="text-center">
                                        <a href={member.redirect}
                                           className="red-btn text-white d-inline-block mt-4 text-decoration-none rounded">
                                            {member.a_text}
                                        </a>
                                    </div>

                                </div>
                            ))}

                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

function PlatformFeatures() {
    return (
        <>
            {/* title of the page */}
            <div className="row wow fadeIn">
                <div className="col-md-12">
                    <h1>Platform Features</h1>
                </div>
            </div>

            <div className="row py-5 text-center wow zoonIn">

                <div className="d-md-inline-flex">
                    {/* 4 features */}
                    {platform_features.map(feature => (
                        <div key={feature.id} className={feature.className}>
                            <img src={feature.imageSrc} alt="Feature-image1"/>
                            <h6 className="text-white pt-4 pb-5">{feature.heading}</h6>
                            <p className="text-white">{feature.text}</p>
                        </div>

                    ))}
                </div>

                <div className="text-center pt-3">
                    <a href="/features"
                       className="d-inline-block mt-3 view-more rounded text-white text-decoration-none">
                        Browse All Features</a>
                </div>

            </div>
        </>
    )
}

function Team(props) {
    return (

        <React.Fragment>
            {/* show team members in carousel form */}
            {/* data to render as crousel , title of the part of the carousel,
            memberInfoStyle --> style of the info of the team rendered,
            memberSizeStyle --> set the height and width of the team member to render */}
            <CarouselLook
                data={home_team}
                title="Team"
                memberInfoStyle={{height: "130px"}}
                memberSizeStyle={{height: "400px", width: "220px"}}
                isMobile={props.isMobile}/>

            <div style={{textAlign: "center", float: "bottom"}}>
                <a href="/about#team"
                   className="d-inline-block mt-3 view-more rounded text-white text-decoration-none handleSpace">
                    Meet All of Us
                </a>
            </div>

        </React.Fragment>
    )
}


export default HomePage
