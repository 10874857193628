import React from 'react'
import {DataToRender} from '../../utils/constants'

// to render the title of the page
const TitleRendering = () => {
    return (
        <div className="row text-center wow zoomIn" 
            style={{ marginTop: "25px", marginBottom: "25px" }} >
            <a href="#firstVideo">
                <div className="col-md-12">
                    <h1>TinkerWorld - Hall of fame</h1>
                </div>
            </a>
        </div>
    )
}

// render the side images
const SideImages = () => {
    return (
        <>
            <img src="images/bg-5.png" alt="bg5" className="bg-5" />
            <img src="images/bg6.png" alt="bg6" className="bg6" />
        </>
    )
}

// render image
const RenderImage = ({ image_source }) => {
    // image_source --> image to render on the screen

    return (
        <img src={image_source}
            alt="schools"
            className="img-fluid"
            style={{ marginTop: "-80px" }} />
    )
}

// render the written content
const RenderWrittenContent = ({ id, mobile }) => {
    //id --> id of the item to render, mobile --> is the screen size is of mobile or not

    return (
        <div className={mobile ? " " : "py-5"}>
            {/* render the written content as per id to render */}
            <p className={DataToRender[id].div.pClass}>
                {DataToRender[id].div.p1}
                <b>{DataToRender[id].div.b1}</b>
                {DataToRender[id].div.p2}
                <b>{DataToRender[id].div.b2}</b>
                {DataToRender[id].div.p3}
            </p>
        </div>
    )
}

// check which element to render according to the screen side and positioning
// mentioned in the rendering array
const RenderElement = ({ id, mobile }) => {
    // id --> id of the object, mobile --> true if the screen size is of mobile

    // rendered if the screen size is of mobile
    if (mobile) {
        return (
            <div className={DataToRender[id].image.classN_web}>
                <div>
                    <RenderImage
                        image_source={DataToRender[id].image.src} />
                    <RenderWrittenContent
                        mobile={mobile}
                        id={id} />
                </div>
            </div>
        )
    }
    // render if the screen size is not of mobile
    else {
        // rendered if the first element to render is set to be image
        if (DataToRender[id].firstToRender === "image") {
            return (
                <>
                    <div className={DataToRender[id].image.classN_web}>
                        <RenderImage
                            image_source={DataToRender[id].image.src} />
                    </div>

                    <div className={DataToRender[id].div.classN_web}>
                        <RenderWrittenContent
                            id={id} />
                    </div>
                </>
            )
        }
        // if first element to render is written content
        else {
            return (
                <>
                    <div className={DataToRender[id].div.classN_web}>
                        <RenderWrittenContent
                            id={id} />
                    </div>
                    <div className={DataToRender[id].image.classN_web}>
                        <RenderImage
                            image_source={DataToRender[id].image.src} />
                    </div>
                </>
            )
        }
    }

}

// having data of the elements to render on the screen

function HallOfFame(props) {

    // main rendering on the screen
    return (
        <section className="py-5 features" style={{ backgroundSize: "100% 56%" }}>
            <TitleRendering />
            <SideImages />
            <div className="container">
                {/* render for each value in the rendering array */}
                {DataToRender.map(item => (
                    <div key={item.id} className={props.isMobile ? "row" : item.rowClass}>
                        <RenderElement
                            id={item.id}
                            mobile={props.isMobile}
                        />
                    </div>
                ))}
            </div>
        </section>
    )
}

export default HallOfFame
