import React from 'react'
import '../main_style.css'
// handles the rendering of image and the written content on all screen sizes
// values --> object having list of values to render(
        // ImageSource --> image source for screen size greater than the mobile size
        // ImageSourceMob --> image source for screen size of mobile
        // Intro_Video --> introductory video of the student to render on title image of student
// )
// isMobile --> true if the screen size is of mobile
const RenderElements = ({ values, isMobile }) => {
    return (
        <>
            <img
                src={isMobile ? values.ImageSourceMob : values.ImageSource}
                style={{ width: "100%" }} alt="student_image" />
            <div className={isMobile ? "BannerVideoPositionMob center" : "BannerVideoPosition"} >
                <iframe
                    src={values.Intro_Video}
                    title={"banner video"}
                    frameBorder="0"
                    width={isMobile ? "350" : "640"}
                    height={isMobile ? "300" : "360"}
                    allow="autoplay; fullscreen"
                    allowFullScreen={true} />
            </div>
        </>
    )
}

function TitleScreen(props) {

    return (
        <>
            {/* render the element as per the screen size */}
            {props.isMobile ?
                <div className="mx-auto">
                    <div id="tm-video-container">
                        <RenderElements values={props}
                            isMobile={props.isMobile} />
                    </div>
                </div>
                :
                <div id="container3">
                    <RenderElements values={props}
                        isMobile={props.isMobile} />
                </div>
            }


        </>
    )
}

export default TitleScreen
