import React from 'react'

import { Slide } from 'react-slideshow-image'; // to do sliding
import 'react-slideshow-image/dist/styles.css' // css to be add for sliding

// style of the button on slide images of students
const button_Style = {
  color: "white",
  fontWeight: "bold",
}

const SlideShowLook = (props) => {
  return (
    // <div className="slide-container" >

      <Slide duration={1000} >

        {props.slides.map(slide => (
          <div key={slide.id} className="each-slide" >
            <div>

               {/* shows image on each slide */}
              <picture>

               {/* the image to render when the width of the screen < 520 (mobile view) */}
                <source className="imageStyling" media="(max-width: 520px)" srcSet={slide.mob_img} />

                {/* this image is load when the screen size is > 520 (web view) */}
                <source className="imageStyling" media="(min-width: 600px)" srcSet={slide.web_img} />

                {/* the default image to render */}
                <img className="imageStyling" src={slide.web_img} alt="default_image" />

                {/* did not show button on title image or when the object has redirectNotAvailable
                value set to 1 */}

                <a href={slide.redirect} target="_self" className={slide.redirectNotAvailable === "1" ? "hide_button" : "fl-button"} role="button">
                  

                  {/* button to view the information of the student whose image/slide is rendered */}
                  <span id="p1" className="btn btn-primary view_button" style={button_Style}>
                    View My Story
                  </span>

                </a>

              </picture>

            </div>
          </div>
        ))}

      </Slide>

    // </div>
  )
}

export default SlideShowLook
